import { Button, Divider, IconButton, ListItem, ListItemText, Popover, Tooltip } from '@mui/material';
import * as React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { callAIAssist, callAnthropic, callOpenAI, callPaLM, fetchPrompts, fetchPromptsPinned, fetchPromptsWorkspace, refreshAIResponse, uploadFiles, upsertDoc } from 'src/actions/ticketAction';
import { AI_ASSISTANT_DIALOG, FLOW_INPUT_MODAL, FLOW_MODAL, INVITE_FRIENDS_DIALOG, LLM_API_KEY_MODAL, OPERATION_FAILED, PRIVATE_DOCS_ACTIONS, PROMPT_DIALOG, SETTINGS_DIALOG, TEXT_SELECTION, TITLE_SELECTION } from 'src/constants/actionTypes';
import { Magic } from '@styled-icons/bootstrap/Magic'
import { Send } from '@styled-icons/bootstrap/Send';
import { useIntl } from 'react-intl';
import MarkdownRenderer from '../common/MarkdownRenderer'
import 'katex/dist/katex.min.css'
import CircularProgress from '@mui/material/CircularProgress';
import { Warning } from '@styled-icons/fluentui-system-regular/Warning'
import { CheckboxUnchecked } from '@styled-icons/fluentui-system-regular/CheckboxUnchecked'
import { CheckboxChecked } from '@styled-icons/fluentui-system-regular/CheckboxChecked'
import { BubbleChart } from '@styled-icons/remix-line/BubbleChart'

import { Close, KeyboardReturn, Refresh } from '@styled-icons/material';
import { Edit } from "@styled-icons/fluentui-system-regular/Edit";
import { ClipboardCheck } from '@styled-icons/bootstrap/ClipboardCheck';
import { Clipboard } from '@styled-icons/bootstrap/Clipboard';
import { Add } from '@styled-icons/material/Add';
import { Message } from '@styled-icons/boxicons-regular/Message'
import { Selector } from '../common/Selector';
import { getStateByUser } from 'src/reducers/listReducer';
import { cloneDeep } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { geminiStreamGenerate, get_server_host, openAIStreamGenerate } from '@/utils/serverAPIUtil';

import { Handle, Position, useNodeId, NodeToolbar, NodeResizer, useStore as useInternalStore, useReactFlow } from '@xyflow/react';
import useStoreWithUndo, { useStore } from './store';
import { useShallow } from 'zustand/react/shallow';
import { copyNodeOrTextToNote, getSourceHandlePosition, getNewSubNodePosition, onContextMenu, copyNodeItemsToTaskList, getNodeContent } from './utils';
import { Note } from '@styled-icons/fluentui-system-regular/Note';
import { Color } from '@styled-icons/fluentui-system-regular/Color'
import { ColorMenu, node_color_themes } from './ColorMenu';
import { NodeExportMenu } from './NodeExportMenu'
import { Dot } from '@styled-icons/bootstrap/Dot'
import { ArrowMaximize } from '@styled-icons/fluentui-system-filled/ArrowMaximize';
import { ArrowMinimize } from '@styled-icons/fluentui-system-filled/ArrowMinimize';
import { LightningCharge } from '@styled-icons/bootstrap/LightningCharge';
import { ImageAltText } from '@styled-icons/fluentui-system-regular/ImageAltText';
import { BoxArrowRight } from '@styled-icons/bootstrap/BoxArrowRight';
import NoteEditor from './LexicalEditor';
import { useMediaQuery } from 'react-responsive';
import { marked } from 'marked';
import TurndownService from 'turndown';
import { ListTask } from '@styled-icons/bootstrap/ListTask'
import { ArrowDownS } from '@styled-icons/remix-line/ArrowDownS'
import { ArrowUpS } from '@styled-icons/remix-line/ArrowUpS'
import { MindMap } from '@styled-icons/remix-editor/MindMap'
import { PromptNode } from './PromptNode';
import { AIActionList } from './AIActionList';
import { TodoList } from './TodoList';
import { PromptTypeDropdownAndActionButton } from './PromptTypeDropdownAndActionButton';
import { GroupDismiss } from '@styled-icons/fluentui-system-regular/GroupDismiss'
import { MOBILE_MEDIA_QUERY } from '@/utils/constants';
import { TextParagraph } from '@styled-icons/bootstrap/TextParagraph'
import { Save } from '@styled-icons/bootstrap/Save'
import { ImageEdit } from '@styled-icons/fluentui-system-regular/ImageEdit'
import RevealPresentation from './RevealPresentation'
import { PinAngle, PlayBtn } from '@styled-icons/bootstrap';
import { Analyse as Refine } from '@styled-icons/boxicons-regular/Analyse'
import urlRegex from 'url-regex';
import { extractJSONFromString } from '@/utils/jsonStringUtil'
import RegenerateMenu from './RegenerateMenu';
import DownloadButton from './Download';
import Artifact from './Artifact';
import { Link } from '@styled-icons/entypo/Link';
import { FileText } from '@styled-icons/bootstrap/FileText'

const TEXT_LEN_LIMIT = 80000;

const AI_MENU_GROUP_POSITION = [{ group: 3, position: 0 }, { group: 2, position: 1 }, { group: 1, position: 2 }, { group: 0, position: 3 }]

const AI_API_CALLER = {
  openai: callOpenAI,
  groq: callOpenAI,
  'openai_compatible': callOpenAI,
  anthropic: callAnthropic,
  gemini: callPaLM
}

//- text (for detailed explanations, narrative content, or topics requiring coherent exposition)
//- mindmap (for hierarchical information, multi-connected concepts, or topics needing an overall framework)
//- todo_list (for tasks with clear steps or action items, especially those to be completed sequentially)

const Dynamic_Prompt = `As an AI assistant, analyze the user's input(request) and any provided text to generate tailored content. Follow these steps:

1. Determine the optimal output format: text, mindmap, or todo_list.

2. Assess the clarity of the user's request, considering:
   - Specificity
   - Context
   - Actionability
   - Target audience
   - Desired length/depth
   - Any special instructions

3. If the request is unclear or lacks information, create a JSON form to gather necessary details:
   - Limit to ≤7 fields, arranged logically
   - Field structure:
     {
       "name": "field_name",
       "label": "Display name",
       "description": "User-friendly description",
       "type": "input" | "textarea" | "select",
       "options": [{"label": "Option", "value": "Value"}], // For "select" type only
       "value": "Pre-filled or recommended value if applicable"
     }
   - For "select" fields, include 2-5 options and an "Other" option if appropriate

4. If given text is provided:
   - Analyze it for relevant information
   - Pre-fill form fields with extracted data
   - Update 'value' fields accordingly

5. Determine the response:
   - If requirements are clear and all necessary information is available and the optimal format is text, generate content
   - Otherwise, return the JSON form for user input

6. Output the result in RFC8259 JSON format, do not output any intermediate thinking process:
   {
     "generated": {
       "best_output_format": "text" | "mindmap" | "todo_list",
       "is_more_info_needed": true | false,
       "reasoning_for_more_info": "Brief explanation if more information is needed",
       "form": {
         "args": [
           // Include form fields here if more information is needed
         ]
       },
       "output": "Generated content if all requirements are met and format is text"
     }
   }

Prioritize delivering high-quality content that meets the user's needs. If any information is missing or unclear, return the form for completion.`

const Brainstorming_Dynamic_Prompt = `You are a professional brainstorming assistant. Your task is to help users conduct high-quality brainstorming sessions. Please carefully read the brainstorming topic or question provided by the user, then analyze and respond according to the following steps:

1. Understand the given topic/question.
2. Infer the user's underlying needs or goal.
3. Based on the above understanding and inference, determine if the given information is sufficient for you to conduct a high-quality brainstorming session.
4. If the provided information is insufficient, generate a form to request additional information from the user.
  - Form
    - Fields: name, label, description, type, options, value
    a) type: Must be "input" (for short sentences), "textarea" (for long paragraphs) or "select".
    b) options: For "select" fields only, define 2-5 reasonable options.
    c) value: Pre-fill with default or recommended value if applicable.
    d) include {label: 'Other', value: null} as the last item of options if you want to enable user to input their own option for the select.
    - Design: Concise (≤5 fields), logical order, cover key aspects

5. Output in RFC8259-compliant JSON, do not output any intermediate thinking process:
  {
    "generated": {
      "brainstorming_theme": "Restatement of the understanding of the brainstorming topic/question (type: String)",
      "underlying_needs": "A brief paragraph describing the inferred goal or needs (type: String)",
      "is_more_info_helpful": "Is more info from me will help you generate higher quality content meets my needs? (type: Boolean)",
      "reasoning_for_more_info": "Encourage me to provide more information in one sentence (type: String)",
      "form": {
        "args": [
          {
            "name": "field_name",
            "label": "Display name",
            "description": "User-friendly description",
            "type": "input/textarea/select",
            "options": [{"label": "Text", "value": "Value"}],
            "value": "Default"
          }
        ]
      }
    }
  }

Remember, your primary goal is to helps me conduct a high-quality brainstorming session. Seek clarification by generating an appropriate form if uncertain.`

const removeQuotes = (str) => {
  if (!str) {
    return "";
  }

  return str.replace(/^````/, "").replace(/````$/, "");
}

const removeDataURLPrefix = (dataURL) => {
  if (!dataURL) return '';
  const dataURLRegex = /^data:([^;]+);base64,/;
  return dataURL.replace(dataURLRegex, '');
}

const fetchFunBlocksPageContent = async (hid, type) => {
  try {
    const host = await get_server_host();
    const url = type == 'slides' && `${host}slides/hid/${hid}` || type == 'doc' && `${host}doc/hid/${hid}`;
    const response = await fetch(url, {
      credentials: 'include'
    });
    const text = await response.text();
    return text
  } catch (error) {
    console.error('fetch url content err...................', error)
  }
};

const fetchUrlContent = async (url) => {
  try {
    const apiUrl = 'https://r.jina.ai/';
    const response = await fetch(`${apiUrl}${url}`, {
      headers: {
        'Accept': 'application/json'
      }
    });
    const data = await response.json();
    return data
  } catch (error) {
    console.error('fetch url content err...................', error)
  }
};

const searchWeb = async (question) => {
  try {
    const apiUrl = 'https://s.jina.ai/';
    const response = await fetch(`${apiUrl}${question}`, {
      headers: {
        'Accept': 'application/json'
      }
    });
    const data = await response.json();
    return data
  } catch (error) {
    console.error('fetch url content err...................', error)
  }
};

const getBase64Image = async (params) => {
  try {
    const urlObj = new URL(await get_server_host() + 'media/getBase64Image');
    Object.keys(params).forEach(key => urlObj.searchParams.append(key, params[key]));

    const response = await fetch(urlObj, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },

    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data?.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

export const selector = (state) => ({
  nodes: state.nodes,
  setNodes: state.setNodes,
  addSubNode: state.addSubNode,
  addNode: state.addNode,
  deleteNode: state.deleteNode,
  getNode: state.getNode,
  updateNode: state.updateNode,
  updateNodeData: state.updateNodeData,
  edges: state.edges,
  getNodeEdges: state.getNodeEdges
});

const NodeTitle = ({ title, url, color_theme, nodeType, queryType }) => {
  const [isOverflown, setIsOverflown] = React.useState(false);
  const containerRef = React.useRef(null);

  React.useEffect(() => {
    if (containerRef.current) {
      setIsOverflown(containerRef.current.scrollHeight > containerRef.current.clientHeight);
    }
  }, [title]);

  const styles = React.useMemo(() => ({
    container: {
      display: '-webkit-box',
      WebkitLineClamp: 2,
      whiteSpace: 'pre-line',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      WebkitBoxOrient: 'vertical',
      // color: !!url ? 'dodgerblue' : undefined
    }
  }), [color_theme]);

  // const titleEle = React.useMemo(() => !!url ? <a href={url} target='_blank' style={{ textDecoration: 'none' }}>{title}</a> : title, [title, url]);

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: color_theme.title_bg,
      borderBottomWidth: '1px',
      borderColor: color_theme.border,
      padding: '12px',
      paddingTop: 5,
      paddingBottom: 3,
      paddingLeft: (queryType === 'link' || nodeType === 'funblocks_doc') && 6 || 12,
      minHeight: 20,
      fontSize: 14,
      fontWeight: 500,
      borderTopLeftRadius: 3,
      borderTopRightRadius: 3,
    }}>
      {
        (queryType === 'link' || nodeType === 'funblocks_doc') &&
        <div style={{ width: 18, height: 24, marginRight: 4, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          {
            queryType === 'link' && <Link size={18} color={color_theme.border} />
          }
          {
            nodeType === 'funblocks_doc' && <FileText size={18} color={color_theme.border} />
          }
        </div>
      }

      <div ref={containerRef} style={styles.container}>
        {isOverflown ? (
          <Tooltip title={title} arrow>
            <div style={{ height: 'fit-content' }}>{title}</div>
          </Tooltip>
        ) : (
          <div>{title}</div>
        )}
      </div>
    </div>
  );
};

const getSelectionText = () => {
  if (window.getSelection) {
    try {
      var activeElement = document.activeElement
      if (activeElement && activeElement.value) {
        // firefox bug https://bugzilla.mozilla.org/show_bug.cgi?id=85686
        return activeElement.value.substring(
          activeElement.selectionStart,
          activeElement.selectionEnd
        )
      } else {
        return window.getSelection().toString()
      }
    } catch (e) { }
  } else if (document.selection && document.selection.type != 'Control') {
    // For IE
    return document.selection.createRange().text
  }
}

const AINode = ({ data, isConnectable, selected }) => {
  const dialogState = useSelector(state => state.uiState.aiDialog) || {};
  const loginUser = useSelector(state => state.loginIn && state.loginIn.user);
  const prompts = useSelector(state => state.prompts);
  const prompt_lists = useSelector(state => getStateByUser(state.prompt_lists, loginUser));
  const pinned_prompt_lists = useSelector(state => getStateByUser(state.pinned_prompt_lists, loginUser));
  const workspace_prompt_lists = useSelector(state => getStateByUser(state.workspace_prompt_lists, loginUser));
  const public_prompt_lists = useSelector(state => getStateByUser(state.public_prompt_lists, loginUser));

  const [ai_menu_anchor, set_ai_menu_anchor] = React.useState();
  const [sub_menu_anchor, set_sub_menu_anchor] = React.useState();
  const dispatch = useDispatch();
  const intl = useIntl();
  const selectedItemRef = React.useRef(null);
  const contentContainerRef = React.useRef(null);

  const appConfigs = useSelector(state => state.uiState.app_config);
  const assistant_items = appConfigs?.assistant_items;
  const assistant_items_groups = appConfigs?.assistant_items_groups;
  const [ai_items, set_ai_items] = React.useState([]);

  const [searchText, setSearchText] = React.useState('');
  const [itemTargeted, setItemTargeted] = React.useState(0);
  const [sub_menu_parent, set_sub_menu_parent] = React.useState(-1);
  const [sub_menu_items, set_sub_menu_items] = React.useState([]);
  const [sub_menu_item_targeted, set_sub_menu_item_targeted] = React.useState(0);
  const [sub_menu_visible, set_sub_menu_visible] = React.useState(false);
  const [avaliableAssistantItems, setAvaliableAssistantItems] = React.useState(assistant_items);
  const [extendedAssistantItems, setExtendedAssistantItems] = React.useState([]);
  const [filteredAssistantItems, setFilteredAssistantItems] = React.useState([]);
  const [groupedAssistantItems, setGroupedAssistantItems] = React.useState([]);
  const [drafter, setDrafter] = React.useState([]);
  const [selectedText, setSelectedText] = React.useState();

  const [context, setContext] = React.useState();
  const [errMsg, setErrMsg] = React.useState();
  const form_input_ref = React.useRef();
  const imgRef = React.useRef();

  const [userInput, setUserInput] = React.useState();
  const [question, setQuestion] = React.useState('');
  const [show_question_box, set_show_question_box] = React.useState();
  const [show_action_box, set_show_action_box] = React.useState();
  const [show_add_improve_plan_box, set_show_add_improve_plan_box] = React.useState();
  const [action_box_position, set_action_box_position] = React.useState(data.nodeType === 'prompt' ? Position.Bottom : Position.Right);
  const [aigc_hovered, set_aigc_hovered] = React.useState();
  const [menuDroppedDown, setMenuDroppedDown] = React.useState();
  const [form, setForm] = React.useState();

  const { nodes, edges, setNodes, updateNodeData, updateNode, addNode, addSubNode, deleteNode, getNode, getNodeEdges } = useStoreWithUndo(
    useShallow(selector),
  );

  const { setContextMenu, lang, isReadOnly, boardTitle, setBoardTitle, tempLlmModel, shareUrl, setSavingTrigger } = useStore(useShallow(state => ({
    setContextMenu: state.setContextMenu,
    tempLlmModel: state.tempLlmModel,
    lang: state.lang,
    isReadOnly: state.readOnly,
    boardTitle: state.boardTitle,
    setBoardTitle: state.setBoardTitle,
    shareUrl: state.shareUrl,
    setSavingTrigger: state.setSavingTrigger
  })))

  const llm_api_keys = useSelector(state => state.uiState.llm_api_keys);
  const ai_api_model = useSelector(state => state.uiState.ai_api_model);

  const get_model_by_id = React.useCallback((id) => {
    return llm_api_keys?.find(item => item.id === id) || appConfigs?.ai_api_models?.find(item => item.value === id);
  }, [llm_api_keys, appConfigs?.ai_api_models])

  const using_model = React.useMemo(() => {
    const model_id = data.ai_model || tempLlmModel || ai_api_model;
    let model = get_model_by_id(model_id);

    return model;
  }, [ai_api_model, tempLlmModel, data.ai_model, get_model_by_id])

  const selected_model = React.useMemo(() => {
    const model = get_model_by_id(tempLlmModel || ai_api_model);
    return model;
  }, [ai_api_model, tempLlmModel, get_model_by_id])

  const color_theme = React.useMemo(() => node_color_themes.find(theme => theme.id === data.color_theme) || node_color_themes.find(theme => theme.id === 'blue'), [data.color_theme]);

  const [title, setTitle] = React.useState();
  // const [content, setContent] = React.useState();
  const { nodeType, content, selectable_context_option } = data;

  const getObjType = React.useCallback((nodeType) => nodeType == 'image' && 'image' || nodeType == 'note' && 'flow_notes' || 'flow', [])
  const objType = React.useMemo(() => getObjType(nodeType), [nodeType]);
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY)

  const reactFlow = useReactFlow();

  React.useEffect(() => {
    if (nodeType === 'prompt' && !!data.userInput && !userInput) {
      setUserInput(data.userInput);
    }
  }, [data.userInput])

  const nodeId = useNodeId();

  // const { ai_menu, ai_items } = useAIMenu({ objType: 'brainstorming' })

  const set_selectable_context_option = React.useCallback((option) => {
    updateNodeData(nodeId, {
      selectable_context_option: option
    })
  }, [nodeId])

  const node = getNode(nodeId);
  const triggered = React.useMemo(() => getNodeEdges(nodeId, 'source')?.length > 0, [getNodeEdges, edges, nodeId]);

  const prompt_to_ai_item = React.useCallback((prompt) => {
    return {
      label: prompt.name,
      type: 'user_installed',
      desc: prompt.desc,
      action: prompt._id,
      prompt_user: prompt.prompt + (prompt.content_source == 'selected' ? '.\nGiven text: {{selected_text}}' : ''),
      args: prompt.args || [],
      objTypes: [objType]
    }
  }, [objType]);

  const get_ai_prompt_item = React.useCallback((action) => {
    let item = assistant_items?.find(it => it.action == action);
    if (item) return item;

    item = prompt_lists?.items?.find(it => it._id == action) ||
      pinned_prompt_lists?.items?.find(it => it._id == action) ||
      public_prompt_lists?.items?.find(it => it._id == action) ||
      prompts?.byId[action];

    if (!item) return;

    return prompt_to_ai_item(item);
  }, [assistant_items, prompt_lists, pinned_prompt_lists, public_prompt_lists, prompts])

  React.useEffect(() => {
    if (!selected) {
      set_show_question_box(false);
      set_show_add_improve_plan_box(false);
      // !data.showActionBox && set_show_action_box(false);
    }
  }, [selected])

  React.useEffect(() => {
    let items = [];

    assistant_items?.forEach(item => {
      let index = items.findIndex(it => it.action === item._id);
      if (index == -1) {
        items.push(item);
      }
    })

    let my_prompts = prompt_lists?.items
    if (my_prompts?.length > 0) {
      items = items.filter(item => item.action !== 'add_prompt');
      my_prompts.forEach(item => {
        let assist_item = prompt_to_ai_item(item);
        assist_item.group = 9;

        let index = items.findIndex(it => it.action === item._id);
        if (index > -1) {
          items[index] = assist_item;
        } else {
          items.push(assist_item);
        }
      })
    }

    if (pinned_prompt_lists?.items?.length > 0) {
      pinned_prompt_lists.items.forEach(item => {
        let assist_item = prompt_to_ai_item(item);
        assist_item.group = 8;

        let index = items.findIndex(it => it.action === item._id);
        if (index > -1) {
          items[index] = assist_item;
        } else {
          items.push(assist_item);
        }
      })
    }

    if (workspace_prompt_lists?.items?.length > 0) {
      workspace_prompt_lists.items.forEach(item => {
        let assist_item = prompt_to_ai_item(item);
        assist_item.group = 7;

        let index = items.findIndex(it => it.action === item._id);
        if (index > -1) {
          items[index] = assist_item;
        } else {
          items.push(assist_item);
        }
      })
    }

    let draft = items.find(item => item.action == 'draft');
    if (draft) {
      draft.args = draft.args?.filter(arg => arg.name != 'topic');
      setDrafter(draft);
    }

    set_ai_items(items);
  }, [prompt_lists, pinned_prompt_lists, workspace_prompt_lists, assistant_items])


  React.useEffect(() => {
    if (!ai_items) {
      return;
    }

    // if (!selectedText?.trim()) {
    //   return setAvaliableAssistantItems(ai_items.filter(item => item.group === 3 || item.type == 'user_installed'));
    // }

    setAvaliableAssistantItems(ai_items);
  }, [ai_items, selectedText])

  React.useEffect(() => {
    if (!avaliableAssistantItems) return;
    const extended = avaliableAssistantItems
      .filter(item => {
        if (['group', 'slides'].includes(nodeType) && item.group == 0) {
          return false;
        }

        if (nodeType == 'prompt') {
          if (data.queryType !== 'dynamic') return false;

          if (item.type !== 'user_installed') return false;
        }

        if ((data.todos || data.items) && item.group == 0 && !['translate'].includes(item.action)) {
          return false;
        }

        return item.objTypes.includes(objType || 'markdown') || objType === 'flow_notes' && item.objTypes.includes('flow');
      })

    setExtendedAssistantItems(extended);
  }, [avaliableAssistantItems, nodeType, objType, data.todos, data.items]);

  const getFunBlocksPageContent = React.useCallback(async (hid, type) => {
    const text = await fetchFunBlocksPageContent(hid, type);
    updateNodeData(nodeId, {
      content: text
    });
  }, [nodeId])

  React.useEffect(() => {
    if (data?.hid) {
      getFunBlocksPageContent(data.hid, data.nodeType === 'funblocks_doc' ? 'doc' : data.nodeType);
    }
  }, [data?.hid])

  React.useEffect(() => {
    selectedItemRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });
  }, [selectedItemRef]);

  const elmRefs = React.useRef({});
  React.useLayoutEffect(() => {
    if (!elmRefs) return;

    elmRefs[itemTargeted] && elmRefs[itemTargeted].current.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });

    const selectedItem = elmRefs.current[itemTargeted];
    selectedItem && selectedItem.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });

    close_sub_menu()

  }, [itemTargeted]);

  const sub_menu_item_refs = React.useRef([]);
  React.useLayoutEffect(() => {
    if (!sub_menu_item_refs) return;

    sub_menu_item_refs[sub_menu_item_targeted] && sub_menu_item_refs[sub_menu_item_targeted].current.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });

    const selectedItem = sub_menu_item_refs.current[sub_menu_item_targeted];
    selectedItem && selectedItem.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  }, [sub_menu_item_targeted]);

  React.useEffect(() => {
    set_sub_menu_visible(sub_menu_anchor && sub_menu_items);
  }, [sub_menu_anchor, sub_menu_items])

  const onKeyPress = (event) => {
    const itemsCount = sub_menu_visible ? sub_menu_items?.length : filteredAssistantItems.length;

    if (event.key === 'Enter' && !form) {
      if (sub_menu_visible) {
        if (sub_menu_item_targeted < 0 || sub_menu_item_targeted >= itemsCount) {
          return;
        }

        sub_menu_item_clicked(sub_menu_items[sub_menu_item_targeted]);
        event.stopPropagation();
        event.preventDefault();
      }
    }
  }

  const onKeyDown = (event) => {
    const itemsCount = sub_menu_visible ? sub_menu_items?.length : filteredAssistantItems.length;
    if (event.key === 'Escape') {
      event.stopPropagation();
      event.preventDefault();

    } else if (itemsCount && event.key === 'ArrowDown' && !['textarea'].includes(event.target.tagName.toLowerCase())) {
      if (!sub_menu_visible) {
        setItemTargeted(prevState => {
          if (prevState < itemsCount - 1) {
            return prevState + 1;
          }

          return 0;
        });
        event.stopPropagation();
        event.preventDefault();
      } else {
        set_sub_menu_item_targeted(prevState => {
          if (prevState < itemsCount - 1) {
            return prevState + 1;
          }

          return 0;
        });
        event.stopPropagation();
        event.preventDefault();
      }
    } else if (itemsCount && event.key === 'ArrowUp' && !['textarea'].includes(event.target.tagName.toLowerCase())) {
      if (!sub_menu_visible) {
        setItemTargeted(prevState => {
          if (prevState > 0) {
            return prevState - 1;
          }

          return itemsCount - 1;
        })
        event.stopPropagation();
        event.preventDefault();
      } else {
        set_sub_menu_item_targeted(prevState => {
          if (prevState > 0) {
            return prevState - 1;
          }

          return itemsCount - 1;
        })
        event.stopPropagation();
        event.preventDefault();
      }
    } else if (event.key === 'ArrowRight') {
      if (itemTargeted < 0) {
        return;
      }

      if (open_sub_menu(itemTargeted)) {
        event.stopPropagation();
        event.preventDefault();
      }
    } else if (event.key === 'ArrowLeft') {
      if (sub_menu_visible) {
        close_sub_menu()
        event.stopPropagation();
        event.preventDefault();
      }
    }
  }

  const open_sub_menu = React.useCallback((parent_item) => {
    let item = groupedAssistantItems.find(it => it.order == parent_item);
    if (!item?.sub_items) {
      return false;
    }

    if (sub_menu_visible && sub_menu_parent === parent_item) {
      close_sub_menu()
      return false
    }

    set_sub_menu_parent(parent_item);
    set_sub_menu_items(item?.sub_items);
    set_sub_menu_anchor(elmRefs.current[parent_item]);

    return true;
  }, [groupedAssistantItems, sub_menu_visible, sub_menu_parent, elmRefs])

  const close_sub_menu = React.useCallback(() => {
    set_sub_menu_visible(false);
    set_sub_menu_anchor(null);
    set_sub_menu_items(null);
  }, [])

  React.useEffect(() => {
    if (!aigc_hovered) return;

    if (data.nodeType === 'prompt') {
      return set_action_box_position(Position.Bottom);
    }

    set_action_box_position(Position.Right);
  }, [aigc_hovered, data.queryType])

  const aiItemClicked = React.useCallback(({ noNewNode, originNodeType, item, sub_item, queryType, nodeType, userInput, context, color_theme, showActionBox, context_node_id, standAlone, ai_generated }) => {
    if (item.llms && !item.llms.includes(selected_model?.value)) {
      dispatch({
        type: FLOW_MODAL,
        value: {
          visible: true,
          action: 'action_requires_specific_models',
          params: {
            required_models: item.llms
          }
        }
      })
      return;
    }
    
    const node = getNode(nodeId);
    const data = node.data;

    if (noNewNode) {
      updateNodeData(nodeId, {
        nodeType: 'aigc',
        originNodeType,
        title: sub_item?.label || item.label,
        ai_action: item.action,
        ai_sub_item: sub_item,
      })
    } else {
      let newNode = {
        id: new Date().getTime() + '',
        position: getNewSubNodePosition(node, getNode, getNodeEdges, item.action === 'flow_mindmap' ? 820 : 100),
        data: {
          nodeType: nodeType || 'aigc',
          title: ['optimize'].includes(item.action) && data.title || userInput || sub_item?.label || item?.label || data.userInput,
          ai_action: item.action,
          ai_sub_item: sub_item,
          context_node_id: context_node_id || (!context && !standAlone ? nodeId : undefined),
          context,
          userInput: userInput,
          ai_generated,
          queryType: queryType || (item.action == 'query' ? data.queryType : undefined),
          color_theme: color_theme?.id,
          showActionBox,
          trigger: nodeType === 'prompt' && ['search', 'ask', 'link'].includes(queryType) ? userInput : undefined,
        },
        type: 'ai_node'
      }

      if (!standAlone) {
        addSubNode(nodeId, newNode);
      } else {
        addNode(newNode);
      }
    }

    set_ai_menu_anchor(null);
    set_show_action_box(false);
  }, [nodeId, selected_model]);

  const actionItemClicked = React.useCallback((params) => {
    updateNodeData(nodeId, {
      showActionBox: false
    })
    aiItemClicked(params);
  }, [nodeId])

  const itemSelected = React.useCallback(({ order, action }) => {
    if (isReadOnly) {
      dispatch({
        type: FLOW_MODAL,
        value: {
          visible: true,
          action: 'copy_to_editable'
        }
      })

      return;
    }

    let item = !action ? groupedAssistantItems.find(it => it.order == order)
      : (ai_items.find(it => it.action == action) || groupedAssistantItems.find(it => it.action == action));
    item = item || {
      action: dialogState.action
    }

    if (item.action === 'add_prompt') {
      gotoPrompts()
      return;
    }

    if (typeof item.action === 'function') {
      return item.action();
    } else if (item.sub_items) {
      open_sub_menu(order);
      return;
    }

    aiItemClicked({ item, noNewNode: nodeType === 'prompt', originNodeType: nodeType });

    set_show_question_box(false);
  }, [isReadOnly, ai_items, groupedAssistantItems, dialogState, open_sub_menu, aiItemClicked])

  const sub_menu_item_clicked = React.useCallback((item) => {
    close_sub_menu();

    const parent_item = groupedAssistantItems.find(it => it.order == sub_menu_parent);
    if (!parent_item) return;

    aiItemClicked({ item: parent_item, sub_item: item, noNewNode: nodeType === 'prompt', originNodeType: nodeType });

    set_show_question_box(false);
  }, [groupedAssistantItems, sub_menu_parent, aiItemClicked]);

  const generateSlides = React.useCallback(() => {
    itemSelected({ action: 'slideshow' })
  }, [itemSelected])

  React.useEffect(() => {
    if (!data.trigger) return;

    const doit = async () => {
      if (['search', 'ask', 'link'].includes(data.queryType)) {
        updateNodeData(nodeId, {
          trigger: undefined,
          // backgroundTasks: {
          //   trigger: Math.random()
          // }
        })

        const turndownService = new TurndownService();
        const mrkd = data.queryType === 'link' ? data.userInput : turndownService.turndown(data.userInput)?.trim();

        if (data.queryType === 'ask') {
          aiItemClicked({ item: { action: 'query' }, userInput: mrkd });
        } else if (data.queryType === 'search') {
          set_context_loading(true)
          const response = await searchWeb(mrkd);
          set_context_loading(false)

          // console.log('response............',mrkd, response)
          if (!response?.data) {
            return;
          }
          aiItemClicked({ item: { action: 'search_summary' }, queryType: data.queryType, userInput: mrkd, context: response.data });
        } else if (data.queryType === 'link') {
          set_context_loading(true)
          const response = await fetchUrlContent(mrkd);
          set_context_loading(false)

          if (!response?.data) {
            return;
          }

          updateNodeData(nodeId, {
            title: response.data.title,
            nodeType: 'aigc',
            ai_action: 'summary',
            context: response.data,
          })
        }
      }
    }

    doit();
  }, [data?.trigger])

  const userInitPrompt = React.useCallback(async (prompt = userInput, options) => {
    const turndownService = new TurndownService();
    // const mrkd = turndownService.turndown(prompt)?.trim();
    const mrkd = prompt.trim();

    if (['dynamic'].includes(data.queryType)) {
      aiItemClicked({ item: { action: 'dynamic' }, userInput: mrkd });

      updateNodeData(nodeId, {
        userInput: prompt,
      })
    } else if (data.queryType === 'breakdown') {
      updateNodeData(nodeId, {
        title: mrkd,
        nodeType: 'aigc',
        userInput: mrkd,
        ai_action: 'breakdown',
      })
    } else if (data.queryType === 'brainstorming') {
      let input = mrkd;
      if (options?.scenario) {
        // input += `\n\n[Brainstorming intention/scenario]: ${options.scenario};`
        input = options.scenario + ': ' + input;
      }

      input = `[Brainstorming theme]: "${input}"`;

      if (options?.thinking_model) {
        input += `\n\n[Specified thinking model]: "${options.thinking_model}"\n\n`
      }

      updateNodeData(nodeId, {
        title: mrkd,
        userInput: input,
        nodeType: 'aigc',
        ai_action: 'dynamic_brainstorming',
      })
    } else if (data.queryType === 'todos') {
      updateNodeData(nodeId, {
        title: mrkd,
        userInput: mrkd,
        nodeType: 'aigc',
        ai_action: 'flow_task_breakdown',
      })
    } else if (['search', 'ask', 'link'].includes(data.queryType)) {
      if (data.queryType === 'link' && !urlRegex({ exact: true }).test(prompt)) {
        return;
      }
      updateNodeData(nodeId, {
        userInput: prompt,
        trigger: prompt
      })
    }
  }, [nodeId, data]);

  // const askQuestion = () => {
  //   const turndownService = new TurndownService();

  //   aiItemClicked({ item: { action: 'query' }, queryType: 'ask', userInput: turndownService.turndown(question) });

  //   setQuestion('');
  // }

  React.useEffect(() => {
    selectable_context_option && setSelectedText(getSelectableContext(nodeId, data, selectable_context_option, getContextNode()))
  }, [selectable_context_option, nodes])

  const askAI = React.useCallback(() => {
    // if (searchText && !loading) {
    //   aiAction({ action: 'query', userInput: searchText, aiResponse });
    // } else 
    if (form) {
      const validArgs = form.args.filter(arg => !arg.disabled);
      const required_args = validArgs?.filter(arg => arg.required);

      if (required_args?.length > 0) {
        for (const arg of required_args) {
          if (!arg.value?.trim()) {
            return showErrorMsg('missing_required_data');
          }
        }
      } else if (validArgs?.length > 0) {
        if (selectable_context_option == 'none' && !validArgs.find(arg => !!arg.value?.trim())) {
          return showErrorMsg('missing_one_data');
        }
      }

      updateNodeData(nodeId, { form })

      aiAction({ action: form.item.action, sub_item: form.sub_item, userInput: form.userInput, selectedText: form.selectedText, context: form.context, aiResponse });
    }
    // else if (!searchText && !aiResponse) {
    //   setErrMsg(intl.formatMessage({ id: 'should_text_or_select_item' }))
    // } else if (aiResponse) {
    //   setErrMsg(intl.formatMessage({ id: 'should_select_item' }))
    // }
  }, [form, selectable_context_option])

  const [copied, setCopied] = React.useState();

  const getAIGenerated = React.useCallback(() => {
    if (data.ai_action === 'translate') {
      return `## ${title}\n\n${content}`;
    }

    if (data.items || data.todos) {
      return `JSON\n\n{generated: ${JSON.stringify(data.items || data.todos)}}`
    }

    return content;
  }, [data.ai_action, content, title, data.items, data.todos])

  const getDescendants = React.useCallback((nodeId, excludes) => {
    const visitedNodes = new Set();
    const visitedEdges = new Set();

    const traverse = (currentNodeId) => {
      if (visitedNodes.has(currentNodeId) || excludes.includes(currentNodeId)) return;
      visitedNodes.add(currentNodeId);

      const filtered_edges = edges.filter(edge => edge.source === currentNodeId);
      filtered_edges.forEach(edge => {
        visitedEdges.add(edge);
        traverse(edge.target);
      });
    };

    traverse(nodeId);

    const descendantNodes = Array.from(visitedNodes).map(id => getNode(id));
    const descendantEdges = Array.from(visitedEdges);

    if (!descendantNodes.find(node => !!getNodeContent(node, 'summary'))) {
      return null;
    }

    return {
      nodes: descendantNodes.map(node => {
        if (!node.data) {
          return {
            id: node.id,
          }
        }
        return {
          id: node.id,
          title: node.data.title,
          content: getNodeContent(node, 'summary')
        }
      }),
      edges: descendantEdges.map(edge => ({
        source: edge.source,
        target: edge.target
      }))
    };
  }, [getNode, edges]);

  const getGroupedNodes = React.useCallback((groupId) => {
    const groupedNodes = nodes.filter(n => n.parentId == groupId);
    const groupedNodeIds = groupedNodes.map(n => n.id);
    const groupedEdges = edges.filter(e => groupedNodeIds.includes(e.source) && groupedNodeIds.includes(e.target));

    if (!groupedNodes.find(node => !!getNodeContent(node, 'content'))) {
      return null;
    }

    return {
      nodes: groupedNodes.map(node => {
        if (!node.data) {
          return {
            id: node.id,
          }
        }

        return {
          id: node.id,
          title: node.data.title,
          content: getNodeContent(node, 'content')
        }
      }),
      edges: groupedEdges.map(edge => ({
        source: edge.source,
        target: edge.target
      }))
    }

  }, [nodes, edges]);

  const getSelectableContext = React.useCallback((nodeId, data, context_scope, context_node) => {
    if (context_scope === 'selected_node_and_descendants') {
      const descendants = getDescendants(context_node?.id, [nodeId]);
      if (!descendants) return '';

      return JSON.stringify(descendants) + '\n(above provided content is a stringified JSON representation of a mindmap data structure. It contains nodes representing topics/concepts/content, and their hierarchical relationships are organized in a tree format using the \'source\' and \'target\' properties on the edges connecting the nodes)';
    } else if (context_scope === 'selected_node') {
      return getGivenText(context_node, data.ai_action, false)
    }
  }, [edges, nodes])

  const getContextNode = React.useCallback(() => {
    const as_context = ['link', 'search'].includes(data.queryType) || ['image', 'group', 'note'].includes(data.nodeType);
    // console.log('get context node..........', as_context, data.context_node_id)
    const context_node = getNode(data.context_node_id || as_context && nodeId || 0);

    return context_node;
  }, [data])

  const getGivenText = React.useCallback((node, ai_action) => {
    if (!node?.data) return;

    if (['translate'].includes(ai_action)) {
      return 'this text is a JSON structured string: ````json\n' + JSON.stringify({
        title: node.data.title,
        userInput: node.data.userInput,
        content: node.data.content,
        todos: node.data.todos,
        items: node.data.items,
        related_questions_topics: node.data.related_questions_topics,
        query_optimize: node.data.query_optimize,
      }) + '\n````. Please translate only the data value for each JSON field. And output your result with the same structured JSON'
    }

    if (['optimize'].includes(ai_action)) {
      return node.data.content;
    }

    if (node.data.nodeType == 'group') {
      const groupedNodes = getGroupedNodes(node.id);

      return JSON.stringify(groupedNodes) + '\n(above provided content is a stringified JSON representation of a group of nodes an whiteboard. It contains nodes representing topics/concepts/content, and their hierarchical relationships are organized in a tree format using the \'source\' and \'target\' properties on the edges connecting the nodes)';
    }

    if (node.data.todos && node.data.contentType === 'todos') {
      return `[Project]:${node.data.title}\n\n[Main Task List]:\n${JSON.stringify(node.data.todos, null, 2)}.`
    }

    if (node.data.items) {
      return `[Topic]:${node.data.title}\n\n[Items]:\n${JSON.stringify(node.data.items, null, 2)}.`
    }

    if (node.data.context) {
      const actionItem = get_ai_prompt_item(ai_action);
      if (actionItem?.group === 0) {
        return node.data.content;
      }

      if (node.data.queryType === 'link') {
        return '# ' + node.data.context.title + '\n\n' + node.data.context.content;
      } else if (node.data.queryType === 'search') {
        let searchResults = node.data.context.map((item, index) => {
          return `[${index}] Title: ${item.title}
            [${index}] URL Source: ${item.url}
            [${index}] Markdown Content:
            ${item.content || item.description}
            `
        }).join('\n\n');

        console.log('search results length...............', searchResults.length)

        return `[My Question]: ${node.data.userInput}

          [Search engine results]: 
          ${searchResults}
          `
      }
    } else if (node.data.queryType === 'brainstorming') {
      return node.data.content || node.data.title;
    } else if (node.data.nodeType === 'prompt') {
      return node.data.userInput
    } else if (node.data.nodeType === 'image') {
      return !!node.data.content.caption && `image caption: ${node.data.content.caption}`
    }

    return node.data.content
  }, [])

  const getGivenContext = React.useCallback((context_node_id, use_summary) => {
    const getNodeChain = (node_id) => {
      const chain = [];
      let current_id = node_id;
      while (current_id) {
        const node = getNode(current_id);
        if (!node?.data || ['prompt', 'image'].includes(node.data.nodeType)) break;
        chain.unshift(node);
        current_id = node.data.context_node_id;
      }
      return chain;
    };

    const getNodeContext = (node) => {
      if (!node?.data) return '';

      const { nodeType, queryType, ai_action, userInput, title } = node.data;
      let context = '';

      if (nodeType == 'image' && node.data.content?.caption) {
        context = '[Given image] caption: ' + node.data.content.caption;
      } else if (['note', 'group'].includes(nodeType) || ['brainstorming', 'link'].includes(queryType)) {
        context = `[${nodeType == 'note' && 'Given text'
          || nodeType == 'group' && 'Given context'
          || queryType === 'brainstorming' && 'Brainstorming theme'
          || queryType === 'link' && 'Given article'
          }]: {${use_summary && node.data?.related_questions_topics?.summary || getGivenText(node)}} `;
      } else if (queryType === 'search' || nodeType === 'prompt') {
        context = '';
      } else {
        let content = use_summary && node.data?.related_questions_topics?.summary || node.data.content;
        let userPrompt = title || userInput;

        if (node.data.todos) {
          userPrompt = ai_action === 'flow_todolist' && 'Extract To-Do list from give content' || ai_action === 'flow_task_breakdown' && `Breakdown given task: ${userInput}` || 'To-Do list'
          content = JSON.stringify(node.data.todos, null, 2);
        } else if (node.data.items) {
          userPrompt = `Breakdown: ${title}`
          content = JSON.stringify(node.data.items, null, 2);
        }

        context = `[User]: {${userPrompt}};\n\n[Assistant]: {${content}};`;
      }

      return context;
    };

    const nodeChain = getNodeChain(getNode(context_node_id)?.data?.context_node_id);
    const relevantNodes = nodeChain.length <= 3 ? nodeChain : [nodeChain[0], ...nodeChain.slice(-2)];

    return relevantNodes.map(getNodeContext).filter(Boolean).join('\n\n');
  }, [getNode, getGivenText]);

  const getAIContextType = React.useCallback(() => {
    const context_node = getNode(data.context_node_id || nodeId);

    return context_node?.data?.nodeType;
  }, [data])

  const copy = React.useCallback(() => {
    const node = getNode(nodeId)
    navigator.clipboard.writeText(nodeType !== 'image' ? getNodeContent(node) : getNodeContent(node).src).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    });
  }, [nodeId]);

  const handleCopyToNote = React.useCallback(() => {
    const node = getNode(nodeId);
    const newNode = copyNodeOrTextToNote(node, getNodeContent(node));

    addNode(newNode);

    setSavingTrigger(Math.random());
  }, [nodeId])

  const [loading, setLoading] = React.useState();
  const [context_loading, set_context_loading] = React.useState();
  const [subTasking, setSubTasking] = React.useState();
  const [cancelled, setCancelled] = React.useState();
  const [doing, setDoing] = React.useState();
  const [aiRespItem, setAiRespItem] = React.useState();
  const [aiResponse, setAiResponse] = React.useState();
  const [prevAIResponse, setPrevAIResponse] = React.useState();
  const [aiResultRefresher, setAiResultRefresher] = React.useState();
  const [artifacts, setArtifacts] = React.useState([]);
  const [selectedArtifact, setSelectedArtifact] = React.useState(null);
  const [artifactOffsetTop, setArtifactOffsetTop] = React.useState(0);

  const update_dynamic_arg = React.useCallback((prompt, args, selectedText, selectable_context_option) => {
    if (!prompt?.includes('{{selected_text}}')) {
      return;
    }

    let dynamic_arg = args.find(arg => arg.name == 'selected_text');
    if (dynamic_arg) {
      dynamic_arg.disabled = !!selectedText && ['selected_node', 'selected_node_and_descendants'].includes(selectable_context_option);
    } else {
      args.unshift({
        disabled: !!selectedText && ['selected_node', 'selected_node_and_descendants'].includes(selectable_context_option),
        name: 'selected_text',
        type: 'text',
        label: intl.formatMessage({ id: 'ask_ai_about_selected' }),
        required: true,
        hint: intl.formatMessage({ id: 'ask_ai_about_given_text_hint' })
      });
    }
  }, [intl])

  React.useEffect(() => {
    if (!data?.ai_action || ['initNode'].includes(data.ai_action) || data?.aigc_triggered || errMsg) {
      return;
    }

    const use_context = ['tell_more', 'ask', 'ask_question', 'breakdown'].includes(data.queryType);
    const context_node = getContextNode();
    const aiContextType = getAIContextType();

    const given_text = data.ai_action === 'summary' && (['flow_mindmap', 'flow_brainstorming'].includes(context_node?.data?.ai_action) || context_node?.data?.todos || context_node?.data?.items) && getSelectableContext(nodeId, data, 'selected_node_and_descendants', context_node)
      || data.queryType !== 'tell_more' && aiContextType !== 'image' && getSelectableContext(nodeId, data, selectable_context_option || 'selected_node', context_node)

    setSelectedText(given_text);

    const context = aiContextType == 'image'
      ? (context_node.data.content.caption ? `The image also has a caption: "${context_node.data.content.caption}"` : '')
      : use_context && getGivenContext(data.queryType === 'tell_more' ? nodeId : data.context_node_id, data.queryType === 'tell_more');

    // const content_as_input = ['link', 'search'].includes( data.queryType);
    const selected_text = given_text;

    // console.log('selected text...............', { use_context, aiContextType,  content_as_input, selected_text, context, given_text})
    const userInput = (!!data.userInput && (
      data.queryType == 'tell_more' && ('Tell me more about: ' + data.userInput)
      || data.queryType == 'perspective' && ('Perspective to brainstorming: ' + data.userInput)
      || data.queryType == 'mindmap_primary_branch' && ('Specified topic: ' + data.userInput)
      || data.ai_action == 'breakdown' && ('Topic to breakdown: ' + data.userInput)
      || data.ai_action?.startsWith('flow_task') && (`Task to ${data.ai_action.replace('flow_task_', '')}: ` + data.userInput)
      // || (!data.ai_action || (['query'].includes(data.ai_action))) && ('\n[User question or prompt]: ' + data.userInput)
    )
      || data.ai_action == 'translate_image' && lang && ('[Target Language]: ' + lang.Language)
      || data.userInput
      || ''
    )

    const item = get_ai_prompt_item(data.ai_action);

    if (!data.reflect_and_improve &&
      (item?.args?.length > 0
        || data.ai_sub_item?.args?.length > 0
        || item?.type == 'user_installed'
        || data.ai_sub_item?.type == 'user_installed'
        || data.form
      )) {
      setSearchText('');

      const args = [...(item?.args || data.ai_sub_item?.args || [])];

      update_dynamic_arg(data.ai_sub_item?.prompt_user || item?.prompt_user, args, selected_text, selectable_context_option || 'selected_node');

      setForm(data.form ? {
        ...data.form,
        id: Math.random()
      } : {
        id: Math.random(),
        item,
        sub_item: data.ai_sub_item,
        args,
        selectedText: selected_text,
        context,
        userInput,
      })

      return;
    }

    if (loading) return;

    aiAction({
      selectedText: selected_text,
      context,
      withContext: true,
      userInput,
      backgroundTask: data.reflect_and_improve === 'reflect',
      action: data.ai_action,
      menuItem: data.ai_item,
      sub_item: data.ai_sub_item
    })
  }, [data?.aigc_triggered, data?.ai_action])

  React.useEffect(() => {
    if (form && !form.args?.filter(arg => !arg.disabled)?.length && selectable_context_options?.length < 2) {
      askAI();
    }
  }, [form?.id])

  const triggerSavingAfterGeneration = React.useCallback(() => {
    !data.savingTriggered && setSavingTrigger(Math.random());

    updateNodeData(nodeId, { savingTriggered: true })
  }, [data?.savingTriggered])

  React.useEffect(() => {
    if (['translate', 'translate_image', 'summary', 'slideshow'].includes(data.ai_action) && !data.backgroundTasks?.trigger) {
      data.aigc_done && triggerSavingAfterGeneration();
      return;
    }

    if (['search', 'ask'].includes(data.queryType) && nodeType === 'prompt') {
      if (data.backgroundTasks?.trigger && data.backgroundTasks?.query_optimize !== 'triggered' && !data.ai_generated && data.optimized_query !== data.userInput) {
        aiAction({
          action: data.queryType === 'search' && 'search_optimize' || data.queryType === 'ask' && 'question_optimize',
          userInput: data.userInput,
          backgroundTask: true
        })

        let updateData = {
          backgroundTasks: {
            ...data.backgroundTasks,
            query_optimize: 'triggered'
          },
          showActionBox: true,
        };

        updateNodeData(nodeId, updateData);
      }

      return;
    }

    const aiItem = get_ai_prompt_item(data.ai_action);

    if (!['aigc', 'image', 'note', 'group', 'slides', 'funblocks_doc'].includes(nodeType) ||
      nodeType == 'aigc' && !data.aigc_done && !data.backgroundTasks?.trigger ||
      (['image', 'note', 'group'].includes(nodeType) || data.queryType == 'clarify_notes' || !data.queryType || aiItem?.type === 'user_installed') && !data.backgroundTasks?.trigger ||
      nodeType != 'group' && !(data.content || data.content?.src) || show_action_box === 'reflect' ||
      ['dynamic', 'dynamic_brainstorming', 'breakdown', 'flow_mindmap', 'mindmap_primary_branch', 'flow_brainstorming', 'brainstorming_perspective', 'flow_todolist', 'flow_task_breakdown'].includes(data.ai_action) ||
      data.dynamicTask ||
      !!(data.related_questions_topics?.related_questions || data.related_questions_topics?.related_topics) ||
      data.backgroundTasks?.related_questions_topics == 'triggered'
    ) {
      data.aigc_done && triggerSavingAfterGeneration();
      return;
    }

    aiAction({
      action: nodeType === 'image' ? 'image_insights' : 'related_questions_topics',
      context: 'image' == nodeType && (data.content.caption ? `The image also has a caption: "${data.content.caption}"` : ''),
      selectedText: 'image' != nodeType && ((data.queryType === 'link' || nodeType == 'group') && getGivenText(getContextNode()) || data.content),
      aiContextType: nodeType,
      backgroundTask: true
    })

    let updateData = {
      backgroundTasks: {
        ...data.backgroundTasks,
        query_optimize: 'triggered',
        trigger: undefined
      },
      showActionBox: true,
    };
    updateNodeData(nodeId, updateData);

  }, [data.aigc_done, data.backgroundTasks?.trigger])

  const getSubnodesPositions = React.useCallback((parentNode, childrenNum) => {
    let { width, height } = parentNode.computed || { width: 320, height: 36 };

    const { x, y } = reactFlow.flowToScreenPosition(parentNode.position);
    const parentNodeScreenPosition = { x, y }

    const subnodesPositions = [];

    const angleStep = - (2 * Math.PI) / childrenNum;

    for (let i = 0; i < childrenNum; i++) {
      const angle = i * angleStep;
      const x = parentNodeScreenPosition.x + Math.cos(angle) * (180 + width);
      const y = parentNodeScreenPosition.y + Math.sin(angle) * 300 - 80;
      subnodesPositions.push(reactFlow.screenToFlowPosition({ x, y }));
    }

    return subnodesPositions;
  }, []);


  React.useEffect(() => {
    setTitle(data.title)
  }, [data.title])

  const Output_Formats = React.useMemo(() => ["text", "mindmap", "todo_list"].map(type => ({
    label: intl.formatMessage({ id: type }),
    value: type
  })), [intl])

  const [best_output_format, set_best_output_format] = React.useState();

  React.useEffect(() => {
    // console.log('aigc_done..................', data, content)

    if (!data.aigc_done || !content) return;

    if (data.queryType === 'clarify_notes') {
      updateNodeData(nodeId, {
        nodeType: 'note',
        content,
        title: undefined,
        userInput: undefined
      })
      return
    }

    if (data.reflect_and_improve === 'reflect' ||
      !data.dynamicTask && (!['breakdown', 'flow_mindmap', 'mindmap_primary_branch', 'flow_brainstorming', 'brainstorming_perspective', 'flow_todolist', 'flow_task_breakdown', 'slideshow'].includes(data.ai_action))) return;

    const jsonResult = extractJSONFromString(content);
    const generated = jsonResult?.generated;
    if (!generated) {
      updateNodeData(nodeId, {
        aigc_done: false,
        content: '',
      })

      return;
    }

    if (data.dynamicTask) {
      let action = 'query';
      let queryType = 'text_generation';

      if (generated.form?.args?.length > 0) {
        let best_output_format = generated.best_output_format;
        action = data.ai_action === 'dynamic_brainstorming' && 'flow_brainstorming' || best_output_format === 'text' && 'query' || best_output_format === 'mindmap' && 'flow_brainstorming' || best_output_format === 'todo_list' && 'flow_task_breakdown';
        queryType = data.ai_action === 'dynamic_brainstorming' && 'brainstorming' || best_output_format === 'text_generation' && 'query' || best_output_format === 'mindmap' && 'brainstorming' || best_output_format === 'todo_list' && 'todos';

        set_best_output_format(best_output_format);

        let args = generated.form.args;
        if (generated.underlying_needs) {
          args.unshift({
            type: 'text',
            label: intl.formatMessage({ id: 'underlying_needs' }),
            value: generated.underlying_needs
          })
        }

        updateNodeData(nodeId, {
          aigc_triggered: false,
          dynamicTask: false,
          content: '',
          queryType,
          ai_action: action,
          form: {
            userInput: data.userInput || data.form?.item?.prompt || data.form?.item?.prompt_user,
            selectedText,
            args: generated.form.args,
            best_output_format,
            ai_generated: true,
            description: generated.reasoning_for_more_info,
            item: {
              action,
            },
          }
        })

        setAiResponse(null);
      } else if (!!generated.output?.trim()) {
        updateNodeData(nodeId, {
          ai_action: action,
          queryType,
          content: generated.output,
          dynamicTask: false
        })
      } else {
        updateNodeData(nodeId, {
          dynamicTask: false
        })
      }
    } else if (['breakdown'].includes(data.ai_action)) {
      let title = data.title;
      if (generated?.main_subject && data.title === get_ai_prompt_item(data.ai_action)?.label) {
        title = generated.main_subject
      }

      updateNodeData(nodeId, {
        aigc_done: false,
        content: '',
        items: generated?.subtopics,
        title
      })
    } else if (['flow_todolist', 'flow_task_breakdown'].includes(data.ai_action)) {
      const timeStamp = new Date().getTime();
      const priorities = []
      generated.items?.forEach(item => {
        if (!priorities.includes(item.priority)) {
          priorities.push(item.priority)
        }
      })

      if (!priorities.length) {
        priorities = ['high', 'medium', 'low'];
      }

      updateNodeData(nodeId, {
        aigc_done: false,
        content: '',
        contentType: 'todos',
        todos: generated.items?.map((item, index) => {
          item.id = timeStamp + index;
          return item;
        }),
        priorities: priorities.length > 0 ? priorities : undefined,
      })
    } else if (['slideshow'].includes(data.ai_action)) {
      if (generated.slides?.length) {
        const title = generated.title;
        const slides_mkd = generated.slides?.map(slide => `${!!slide.title && ('## ' + slide.title + '\n\n') || ''}${slide.content}\n\nNotes: ${slide.notes}`).join('\n\n---\n');

        let newdoc = {
          parent: 'root',
          type: 'slides',
          markdown: slides_mkd,
          title
        };

        dispatch(upsertDoc({
          data: {
            space: 'private',
            orgId: loginUser.workingOrgId,
            doc: newdoc,
          }
        }, (doc) => {
          updateNodeData(nodeId, {
            aigc_done: false,
            content: '',
            hid: doc.hid,
            nodeType: 'slides',
            savingTriggered: Math.random()
          })

          setSavingTrigger(Math.random())
        }, 'editor'));
      }


    } else if (['brainstorming_perspective', 'mindmap_primary_branch'].includes(data.ai_action)) {
      let newNode = {
        aigc_done: false,
        content: '',
        items: generated.items,
      }
      if (generated.title) {
        newNode.title = generated.title
      }

      updateNodeData(nodeId, newNode)
    } else {

      const node = getNode(nodeId);

      const perspectives = generated?.key_perspectives || generated?.primary_branches;

      if (perspectives?.length > 0) {
        const positions = getSubnodesPositions(node, perspectives.length);

        perspectives.map((item, index) => {
          if (!item.branches) return;

          const color_theme = node_color_themes[index % node_color_themes.length];

          let newNode = {
            id: (new Date().getTime() + index) + '',
            // position: getNewSubNodePosition(node, getNode, getNodeEdges),
            position: positions[index],
            data: {
              nodeType: 'aigc',
              queryType: data.ai_action === 'flow_brainstorming' && 'perspective' || data.ai_action === 'flow_mindmap' && 'mindmap_primary_branch',
              ai_action: data.ai_action === 'flow_brainstorming' && 'brainstorming_perspective' || data.ai_action === 'flow_mindmap' && 'mindmap_primary_branch',
              aigc_triggered: true,
              title: item.name,
              items: item.branches,
              userInput: item.name,
              context_node_id: nodeId,
              // context: { topic: generated.central_topic || node.data.title },
              color_theme: color_theme?.id,
            },
            type: 'ai_node'
          }

          addSubNode(nodeId, newNode);
        })
      }

      if (generated?.summary_insights) {
        let newNode = {
          id: (new Date().getTime() + 1000) + '',
          position: getNewSubNodePosition(node, getNode, getNodeEdges),
          data: {
            nodeType: 'aigc',
            queryType: 'brainstorming_insights',
            // ai_action: 'brainstorming_perspective',
            context_node_id: nodeId,
            aigc_triggered: true,
            title: intl.formatMessage({ id: 'summuary_insights' }),
            items: generated?.summary_insights,
            // context: { topic: generated.central_topic || node.data.title },
            color_theme: node_color_themes.find(theme => theme.id === 'blue')?.id,
          },
          type: 'ai_node'
        }

        addSubNode(nodeId, newNode);
      }

      updateNodeData(nodeId, {
        aigc_done: false,
        content: generated?.central_topic,
        brainstorming_scenario: generated?.target_scenario
        // content: `${generated?.target_scenario || ''}${generated?.target_scenario && generated?.central_topic && '\n\n' || ''}${generated?.central_topic || ''}`,
      })
    }
  }, [data.aigc_done])

  React.useEffect(() => {
    set_best_output_format(form?.best_output_format);
  }, [form?.best_output_format])

  React.useEffect(() => {
    if (show_action_box != 'reflect' && data.showActionBox && (!!data.related_questions_topics || !!data.query_optimize || data.items)) {
      set_show_action_box('tell_more');
    }
  }, [data.related_questions_topics, data.query_optimize, data.items])

  React.useEffect(() => {
    const prompt = form?.sub_item?.prompt_user || form?.item?.prompt_user;

    if (!prompt?.includes('{{selected_text}}')) {
      return;
    }

    setForm(prevForm => {
      const args = prevForm?.args || [];
      update_dynamic_arg(prompt, args, selectedText, selectable_context_option);

      return {
        ...prevForm,
        args
      }
    })

  }, [form?.sub_item, form?.item, selectable_context_option, selectedText])

  // const showContextNodesSelector = React.useMemo(() => {
  //   const item = get_ai_prompt_item(data.ai_action);

  //   if (form) {
  //     if (form.ai_generated) return false;

  //     return true;
  //   }

  //   return item?.type !== 'user_installed' && item?.group == 3 || item?.type == 'user_installed'
  // }, [form])

  const selectable_context_options = React.useMemo(() => {
    // const contextNode = getContextNode();
    // const nodeType = contextNode?.data?.nodeType;

    // const hasDescendants = edges.filter(edge => edge.source === contextNode.id && edge.target != nodeId);

    return [{
      value: 'selected_node',
      // }, {
      //   value: 'selected_node_and_descendants',
    }]
    // .filter(item => item.value != 'selected_node_and_descendants' || !!hasDescendants?.length)
    // .map(item => {
    //   item.label = intl.formatMessage({ id: nodeType == 'group' && item.value == 'selected_node' && 'grouped_nodes' || item.value })
    //   return item;
    // })
  }, [intl, getContextNode]);

  React.useEffect(() => {
    !selectable_context_option && set_selectable_context_option(
      'selected_node'
    );
  }, [])

  const generateDone = (aiRespItem, content) => {
    setSubTasking(false);
    setLoading(false);
    setItemTargeted(-1);
    setSearchText('');

    setCopied(false);
    // setForm(null);
    // setContext(null);

    if (aiRespItem?.subTask) {
      setForm(form => {
        let args = cloneDeep(form.args);
        let arg = args.find(arg => arg.name == (aiRespItem.subTask.to_arg || aiRespItem.subTask.dynamic_arg?.name));
        if (arg) {
          arg.orignalValue = undefined;
        }

        return {
          ...form,
          args
        }
      })
    } else {
      setForm(null);
    }

    // setAiRespItem(null);
  }

  const initContent = (aiRespItem) => {
    const { subTask } = aiRespItem;

    if (subTask) {
      setForm(form => {
        let args = cloneDeep(form.args);
        let arg = args.find(arg => arg.name == (subTask.to_arg || subTask.dynamic_arg?.name));
        if (arg) {
          arg.orignalValue = arg.value;
        }

        return {
          ...form,
          args
        }
      })
    }

    let content = aiRespItem.action !== 'continue' ? '' : (aiRespItem.menuItem?.id === 'retry' ? (prevAIResponse?.content || '') : (aiResponse?.content || '')) + '\n\n'
    return {
      ...aiRespItem,
      content
    }
  }

  React.useEffect(() => {
    if (!aiRespItem) {
      return;
    }

    if (aiRespItem.sessionId && cancelled?.sessionId === aiRespItem.sessionId) {
      return;
    }

    if (aiRespItem.err) {
      generateDone(aiRespItem);

      let resp = {
        ...aiRespItem,
      }

      if (aiRespItem.backgroundTask) {
        resp.err = undefined;
        triggerSavingAfterGeneration();
      }

      setAiResponse(resp);
    } else {
      let content = aiRespItem.content;
      if (!aiRespItem.stream) {
        content = initContent(aiRespItem)?.content + content;
      }

      if (aiRespItem.done || !aiRespItem.stream) {
        setPrevAIResponse(aiResponse);

        generateDone(aiRespItem, content);
      }

      if (doing?.subTask) {
        setForm(form => {
          let args = cloneDeep(form.args);
          let arg = args.find(arg => arg.name == (doing.subTask.to_arg || doing.subTask.dynamic_arg?.name));
          if (arg) {
            if (arg.readOnly) {
              arg.value = aiRespItem.content;
            } else {
              arg.value = (arg.orignalValue?.trim() ? arg.orignalValue.trim() + "\n" : "") + aiRespItem.content;
            }
          }

          return {
            ...form,
            args
          }
        })

        return;
      }

      if (aiRespItem.backgroundTask) {
        let updatedData = {};
        let jsonData = extractJSONFromString(content);

        let dataField = doing.action;
        if (doing.action === 'image_insights') {
          dataField = 'related_questions_topics';
        } else if (['question_optimize', 'search_optimize'].includes(doing.action)) {
          dataField = 'query_optimize';
        } else if (data.reflect_and_improve === 'reflect') {
          dataField = 'reflect';
        }

        if (jsonData?.generated) {
          updatedData[dataField] = jsonData.generated;
          if (['question_optimize', 'search_optimize'].includes(doing.action)) {
            updatedData.optimized_query = data.userInput;
          }
          if (data.reflect_and_improve) {
            data.reflect_and_improve = false;
          }
        }
        // else {
        //   data[dataField] = content;
        // }

        updateNodeData(nodeId, updatedData);

        dataField === 'related_questions_topics' && (aiRespItem.done || !aiRespItem.stream) && triggerSavingAfterGeneration();

      } else {
        setAiResponse({
          ...aiRespItem,
          content: content.replace(/^````|````$/g, '')
        });

        let updatedData = {
          content: content.replace(/^````|````$/g, ''),
          aigc_done: aiRespItem.done || !aiRespItem.stream
        };

        if (updatedData.aigc_done && !!content?.trim() && ['translate'].includes(doing.action)) {
          const context_node = getNode(data.context_node_id);
          const json = extractJSONFromString(content);

          updatedData = {
            ...context_node.data,
            ...json,
          }

          if (!context_node.data.content) {
            updatedData.content = '';
          }
        }

        updateNodeData(nodeId, updatedData)
      }

    }
  }, [aiRespItem])

  React.useEffect(() => {
    if (contentContainerRef?.current) {
      contentContainerRef.current.scrollTop = contentContainerRef.current.scrollHeight
    }
  }, [contentContainerRef?.current?.scrollHeight])

  React.useEffect(() => {
    if (!aiResultRefresher) return;

    if (cancelled && cancelled.sessionId === aiResultRefresher.sessionId) {
      return;
    }

    dispatch(refreshAIResponse({ sessionId: aiResultRefresher.sessionId, isChat: false }, (item) => {
      if (item) {
        setAiRespItem({ ...item, menuItem: aiResultRefresher.menuItem });
      } else {
        if (new Date().getTime() - aiResultRefresher.sessionId > 110 * 1000) {
          setLoading(false);
          showErrorMsg('ai_timeout');
          return;
        }

        setTimeout(() => {
          setAiResultRefresher({ ...aiResultRefresher, refreshId: Math.random() })
        }, 5 * 1000)
      }
    }, 'aimodal'))
  }, [aiResultRefresher])

  const [errMsgId, setErrMsgId] = React.useState();
  const showErrorMsg = (err) => {
    setLoading(false);
    setSubTasking(false);

    if (doing?.backgroundTask) {
      return;
    }

    if (err != 'please_set_ai') {
      setErrMsg(intl.formatMessage({ id: err }));
    } else {
      setErrMsg(<div style={{
        color: '#333',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        rowGap: 16,
        fontSize: 16,
        marginTop: 16,
        marginBottom: 16,
        cursor: 'pointer'
      }}>
        {intl.formatMessage({ id: loginUser?._id && 'please_set_api' || (using_model?.level === 'private' && using_model.provider != 'gemini' && nodeType === 'image' ? 'invalid_api_for_image' : 'please_set_ai') })}

        <div style={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: 20
        }}>
          {
            !loginUser?._id &&
            <div
              className='transparent-background'
              style={{
                padding: 4,
                paddingLeft: 12,
                paddingRight: 12,
                border: `1px solid ${color_theme.border}`,
                alignSelf: 'center',
                width: 'fit-content',
                minWidth: 68,
                textAlign: 'center'
              }}
              onClick={() => {
                window.open('/#/login?source=flow', '_blank')
              }}
            >
              {intl.formatMessage({ id: 'login' })}
            </div>
          }

          <div
            className='transparent-background'
            style={{
              padding: 4,
              paddingLeft: 12,
              paddingRight: 12,
              border: `1px solid ${color_theme.border}`,
              alignSelf: 'center',
              width: 'fit-content',
              minWidth: 68,
              textAlign: 'center'
            }}
            onClick={() => {
              gotoAPISetting();
            }}
          >
            {intl.formatMessage({ id: 'flow_settings' })}
          </div>
        </div>
      </div>);
    }

    setErrMsgId(err);
  }

  const llmConfiguredInvalid = React.useMemo(() => {
    return !using_model || using_model.level !== 'private' && !loginUser?._id || using_model.level === 'private' && !(using_model.token && using_model.model)
    // return !aiSettings?.usingAPI && !loginUser?._id
    //   || aiSettings?.usingAPI && (!aiProvider || !aiSettings[aiProvider]?.token || !aiSettings[aiProvider]?.model
    //     || aiProvider != 'gemini' && nodeType === 'image'
    //   )
  }, [loginUser, using_model, nodeType])

  React.useEffect(() => {
    if (!llmConfiguredInvalid && errMsgId == 'please_set_ai') {
      setErrMsg(null);
      setErrMsgId(null);
      setTimeout(() => updateNodeData(nodeId, { aigc_triggered: false }), 500)
    }
  }, [llmConfiguredInvalid])

  const isChinese = React.useCallback((str) => {
    var reg = /[\u4e00-\u9fa5]/g; //使用Unicode字符集范围来匹配中文字符
    return reg.test(str);
  }, [])

  const getImageData = React.useCallback(async (aiContextType) => {
    let image;
    if (aiContextType === 'image') {
      const src = getContextNode().data.content.src;

      if (src?.includes('base64;')) {
        image = src;
      } else {
        try {
          let params = { url: src };

          image = removeDataURLPrefix(await getBase64Image(params));

          if (!image) {
            throw 'no image data'
          }
        } catch (error) {
          console.error('error in loading image data...', error);
          setLoading(false);
          showErrorMsg('failed_load_image')
          return;
        }
      }
    }

    return image;
  }, [imgRef?.current])

  const aiAction = async ({ selectedText, context, aiContextType, menuItem, action, userInput = '', sub_item, subTask, aiResponse, backgroundTask, withContext }) => {
    const updatedData = { aigc_triggered: true, aigc_done: false };

    if (!llmConfiguredInvalid && !backgroundTask) {
      updatedData.ai_model = using_model.value || using_model.id;
    }
    updateNodeData(nodeId, updatedData)

    if (llmConfiguredInvalid && !backgroundTask) {
      return showErrorMsg('please_set_ai');
    }

    const sessionId = new Date().getTime();
    let actionItem = get_ai_prompt_item(action);

    setAiRespItem(null);

    // const aiContextType = getAIContextType();

    if (userInput?.includes(selectedText)) {
      selectedText = '';
    }

    aiContextType = aiContextType || getAIContextType();

    if (!userInput?.trim() && !selectedText && aiContextType != 'image' && !aiResponse?.content && action != 'xSlides' && !form) {
      return !backgroundTask && showErrorMsg('no_text')
      // } else if (!aiSettings?.usingAPI && aiContextType != 'image' && (selectedText?.length > TEXT_LEN_LIMIT || userInput?.length > TEXT_LEN_LIMIT || aiResponse?.content?.length > TEXT_LEN_LIMIT)) {
      //   return !backgroundTask && showErrorMsg('text_too_long')
    } else {
      setErrMsg(null);
    }

    // let actionItem = get_ai_prompt_item(action);
    let newDoing = { selectedText, context, aiContextType, action, sessionId, sub_item, form, subTask, userInput, aiResponse, menuItem, backgroundTask };
    if (actionItem) {
      newDoing.label = actionItem.label;
    } else {
      newDoing.label = doing?.label;
    }

    setDoing(newDoing);

    if (subTask) {
      setSubTasking(subTask.action);
    } else {
      setLoading(true);
    }

    if (!actionItem) {
      actionItem = {
        action
      }
    }

    let content;
    if (aiResponse) {
      //has aiResonpse means action after AI response, otherwise means fresh task for AI.
      if (userInput) {
        content = '[Given text]:{{' + aiResponse.content + '}}\n\n;' + `[user]: {{${userInput}}}`;
      } else {
        content = aiResponse.content;
      }
    } else {
      // console.log('action item.............', actionItem, form)
      let user = userInput;
      if (actionItem.prompt_user) {
        user = actionItem.prompt_user;
      }
      if (form && (!backgroundTask || data.reflect_and_improve)) {
        if (subTask) {
          if (subTask.dynamic_arg && !form.args.find(arg => arg.name == subTask.dynamic_arg.name)) {
            setForm(prevState => {
              let args = [...prevState.args];
              args.unshift(subTask.dynamic_arg)

              return {
                ...prevState,
                args
              }
            })
          }

          user = subTask.prompt_user;
        } else {
          user = form.sub_item?.prompt_user || form.item?.prompt_user || user;
        }
        if (form.sub_item) {
          user = user.replaceAll('{{sub_item}}', form.sub_item.label);
        }

        let unused_args = [];
        for (let arg of form.args) {
          let argValue = arg.value;
          if (arg.type === 'select') {
            argValue = arg.value && arg.options?.find(option => option.value === arg.value)?.label || arg.value;
          }

          if (!arg.disabled && !arg.readOnly) {
            if (user.indexOf(`{{${arg.name}}}`) > -1) {
              user = user.replaceAll(`{{${arg.name}}}`, '````' + (argValue || intl.formatMessage({ id: 'not_provided' })) + '````');
            } else if (argValue) {
              unused_args.push({ ...arg, value: argValue })
            }
          }
        }

        if (unused_args.length > 0) {
          user = user + '.\n' + unused_args.map(arg => {
            return '**' + arg.label + '**:````' + arg.value + '````';
          }).join('\n');
        }
      }

      if (context) {
        if (aiContextType != 'image') {
          content = `[history context]: {
          ${context}
          }`
        } else {
          content = `[Context]: {\nGive following image as context. ${context}\n}`
        }
      }

      if (selectedText) {
        if (user.includes('{{selected_text}}')) {
          user = user.replaceAll('{{selected_text}}', '````' + selectedText + '````');
        } else {
          if (selectable_context_option != 'none') {
            selectedText = '[Given text]: ' + '{\n' + selectedText + '\n}'
          }

          content = (content ? `${content};\n\n` : '') + selectedText
        }
      }

      if (user?.trim()) {
        user = user.trim();
        if (userInput && user.includes('{{user_input}}')) {
          user = user.replaceAll('{{user_input}}', '````' + userInput + '````');
        }

        content = (content ? `${content};\n\n` : '') + (user.startsWith('[') ? user : `[user input]: {\n${user}\n}`)

        if (['dynamic', 'dynamic_brainstorming'].includes(action) || data.originNodeType === 'prompt' && form && !form.args?.filter(arg => !arg.disabled)?.length) {
          content = `${'dynamic_brainstorming' == action ? Brainstorming_Dynamic_Prompt : Dynamic_Prompt}\n\n${content}`;
          updateNodeData(nodeId, {
            dynamicTask: true
          })
          actionItem = {
            ...actionItem,
            action: 'query'
          }
        }
      }
    }

    if (data.reflect_and_improve) {
      content = data.reflect_and_improve === 'reflect' ? `Previous prompt and your previous output:
      [previous prompt]: [{${actionItem.prompt || ''}\n\n${content}}];\n\n[Your previous output]: \`\`\`${getAIGenerated()}\`\`\`;\n\n[Reflection and Evaluation]:
      Please reflect on and evaluate your previous output, considering the following points:
      1. Did the output meet the stated task goals? Which aspects were not adequately addressed?
      2. How was the logical coherence and clarity of the content? Were there any contradictions or abrupt transitions?
      3. Was the output too simplistic or overly complex? Did it consider enough details and scenarios?
      4. Was the output comprehensive, novel, and in-depth? Were there any gaps or lack of innovative thinking?
      5. Any other areas you think need improvement and optimization?

[Improvement Plan]:
      Based on the above reflection and evaluation, please propose a specific improvement plan, outlining how you intend to optimize and enhance the output.

**Output your generation with following JSON structure(RFC8259 compliant JSON only. Ensure that the generated JSON string does not contain any literal control characters. If any control characters are present, replace them with the corresponding escape sequences):**
      \`\`\`json
      {
        "generated": {
          "evaluation": "overall evalution on your previous output",
          "improvement_plan": ["item 1","item 2","item 3"]
        }
      }
      \`\`\`
      ` : `Previous prompt and your previous output:
      [previous prompt]: [{${actionItem.prompt || ''}\n\n${content}}];\n\n[Your previous output]: \`\`\`${getAIGenerated()}\`\`\`;\n\n[Reflection and Improvement Plan]: 
      \`\`\`json
      ${JSON.stringify({ improvement_plan: data.reflect.improvement_plan }, null, 2)}
      \`\`\`

[Instructions]:
      Based on the previous reflection and improvement plan, optimize and regenerate the output while adhering to the format requirements and original task goals. However, do not output the intermediate steps. Only provide the final optimized version demonstrating clear improvements over the previous output.`;

      actionItem = {
        action: 'query',
      }
    }

    // console.log('content...........', content)
    let responseReqs;

    if (!action?.includes('translate')) {
      if (lang && lang.Symbol != 'as_context') {
        responseReqs = `. \n[Response requirements]: 1. Respond in ${lang.Language}`;
      } else {
        responseReqs = `. \n[Response requirements]: 1. Respond in the same language as the user input or given context`;
      }

      // if (actionItem.type != 'user_installed') {
      //   responseReqs += ', apply markdown style if necessary;';
      // }

      if (!content?.toLowerCase().includes('```json') && !actionItem?.prompt?.toLowerCase().includes('```json')) {
        responseReqs += ';\n2. To create high-quality, easily understandable content, use appropriate markdown elements where helpful: Mermaid for diagrams and flowcharts, SVG for sketches or complex visual structures, KaTeX for mathematical formulas, tables for structured data, code blocks with the correct language for code examples;'
      }

      if (actionItem.action === 'query') {
        //   console.log('request concise content...................')
        responseReqs += '\n3. Always respond concisely using bullet points when appropriate, unless requested otherwise.'
      }
    }

    if (using_model?.level === 'private') {
      // if (!aiProvider || !aiSettings[aiProvider]?.token || !aiSettings[aiProvider].model) {
      //   return showErrorMsg('invalid_api_settings');
      // }
      let image = await getImageData(aiContextType);

      let messages = content ? [{
        role: 'user',
        content,
      }] : [];

      let prompt = actionItem.type != 'user_installed' && actionItem.prompt;
      if (actionItem.type != 'user_installed' && actionItem?.sub_items && actionItem?.prompts) {
        prompt = actionItem.prompts[actionItem.sub_items.findIndex(item => item.value === sub_item?.value)];
      }

      // console.log('prompts..........', actionItem, text)

      if (prompt && !subTask) {
        // prompt += '. Reply with markdown format';

        messages.unshift({
          role: 'system',
          content: prompt
        });
      }

      messages[0].content += responseReqs

      // if (!['extend', 'draft'].includes(action)) {
      //   messages[0].content += '. Additionally, while aiming for completeness and richness in your response, please strive for brevity. Provide as much detail as possible within a concise framework.';
      // }

      let params = {
        model: using_model.model
      }

      if (['openai', 'groq', 'openai_compatible'].includes(using_model.provider)) {
        if (image) {
          let user_message = messages.find(msg => msg.role == 'user');
          if (user_message) {
            user_message.content = [{
              type: 'text',
              text: user_message.content
            }, {
              type: 'image_url',
              image_url: {
                url: `data:image/jpeg;base64,${image}`
              }
            }]
          } else {
            messages.push({
              role: 'user',
              content: [{
                type: 'image_url',
                image_url: {
                  url: `data:image/jpeg;base64,${image}`
                }
              }]
            })
          }
        }

        params.messages = messages;
        if (actionItem?.temperature) {
          params.temperature = actionItem?.temperature;
        }
      } else if (using_model.provider == 'gemini') {
        let human_content = messages.length == 1 ? messages[0].content : (messages[0].content + '.\n' + messages[1].content);
        // console.log('human content:', human_content)
        params.contents = [{
          parts: [{ text: human_content }]
        }];

        if (image) {
          params.contents[0].parts.push({
            "inline_data": {
              "mime_type": "image/jpeg",
              "data": image
            }
          })
        }

        params.generationConfig = {
          temperature: actionItem?.temperature
        }
      } else if (using_model.provider == 'anthropic') {
        params.max_tokens = 3000;

        let system = messages.find(msg => msg.role == 'system');
        if (system) {
          params.system = system.content
        }
        params.messages = messages.filter(msg => msg.role != 'system');
      } else {
        return !backgroundTask && showErrorMsg('invalid_api_settings');
      }

      // console.log('action...........', action, JSON.stringify(params))
      // console.log('messages to open ai......', actionItem, prompt, messages)
      if (['openai', 'groq', 'gemini', 'openai_compatible'].includes(using_model.provider)
        && !backgroundTask
        && !['breakdown', 'flow_todolist'].includes(action)) {
        let streamGenerator = openAIStreamGenerate;
        if (using_model.provider == 'gemini') {
          streamGenerator = geminiStreamGenerate;
        }

        streamGenerator(using_model.endpoint, using_model.token, params, () => {
          setAiRespItem(initContent({
            sessionId,
            action,
            menuItem,
            subTask,
            stream: true,
            backgroundTask
          }))
        }, (chunkText) => {
          setAiRespItem(prevState => {
            return {
              ...prevState,
              content: (prevState?.content || "") + chunkText,
              backgroundTask
            }
          })
        }, (errMsg) => {
          setAiRespItem(prevState => {
            return {
              ...prevState,
              err: errMsg,
              backgroundTask
            }
          })
        }, () => {
          setAiRespItem(prevState => {
            return {
              ...prevState,
              done: true,
              backgroundTask
            }
          })
        })
      } else {
        dispatch(AI_API_CALLER[using_model.provider](using_model.endpoint, using_model.token, params, (item) => {
          setAiRespItem({
            content: removeQuotes(item),
            sessionId,
            action,
            menuItem,
            subTask,
            backgroundTask
          });
        }, (errMsg) => {
          setLoading(false);
          setSubTasking(false);
          !backgroundTask && setErrMsg("Failed, reason: " + intl.formatMessage({ id: errMsg }));
          setAiRespItem(prevState => {
            return {
              ...prevState,
              err: errMsg,
              backgroundTask
            }
          })
        }))
      }
    } else {
      const context_node = getNode(data.context_node_id);
      const contextNodeObjType = getObjType(context_node?.data?.nodeType) || 'flow';

      let model = ['related_questions_topics', 'search_optimize', 'question_optimize'].includes(action) && ['t1', 't2'].includes(using_model?.level) && 'gemini-1.5-flash-latest'
        || aiContextType === 'image' && (using_model?.level === 't2' || using_model?.level === 't1' && using_model?.value === 'llama-3.1-70b-versatile') && 'gemini-1.5-flash-latest'
        || using_model?.value;

      dispatch(callAIAssist({
        data: {
          model,
          model_level: appConfigs?.ai_api_models?.find(item => item.value === model)?.level,
          action: (actionItem.type === 'user_installed' || subTask ? 'query' : actionItem?.action) || action,
          sub_item: sub_item?.value,
          service: 'flow',
          objType: actionItem.objTypes?.includes(contextNodeObjType) && contextNodeObjType || actionItem.objTypes?.length == 1 && actionItem.objTypes[0] || contextNodeObjType,
          lang: lang && lang.Symbol != 'as_context' ? lang.Language : undefined,
          content,
          image_url: aiContextType === 'image' ? getContextNode().data.content.src : undefined,
          responseReqs: responseReqs || '[Other requirements]: None',
          // hid: dialogState.hid,
          sessionId,
          app: 'funblocks_web'
        }
      }, (item) => {
        setAiRespItem({ ...item, menuItem, subTask, backgroundTask });
      }, (err) => {
        if (err === 'TIMEOUT' && (!cancelled || cancelled.sessionId != sessionId)) {
          setTimeout(() => {
            // refreshAIResp(sessionId, menuItem, action);
            setAiResultRefresher({ sessionId, menuItem, action, refreshId: Math.random() })
          }, 3 * 1000)
        } else {
          setLoading(false);
          setSubTasking(false);
        }

        setAiRespItem(prevState => {
          return {
            ...prevState,
            err,
            backgroundTask
          }
        })
        // setAiRespItem({
        //   err
        // })
      }))
    }
  }

  const [node_question_type, set_node_question_type] = React.useState();
  const nodeQuestionConfirm = React.useCallback((user_behavior, question) => {
    if (show_question_box === 'add' && !['flow_brainstorming', 'flow_mindmap'].includes(data.ai_action)) {
      if (!question?.trim()) return;

      if (data.queryType === 'todos') {
        let todos = [...(data.todos || [])];
        todos.push({
          id: new Date().getTime(),
          description: question,
          priority: data.priorities ? data.priorities[0] : undefined
        })

        updateNodeData(nodeId, {
          todos
        })

        setSavingTrigger(Math.random());
      } else if (data.items) {
        let items = [...data.items];
        items.push(question)

        updateNodeData(nodeId, {
          items
        })

        setSavingTrigger(Math.random());
      }
    } else {
      set_prompt_node_submit({ user_behavior, question, trigger: Math.random() })
    }

    setQuestion('');
  }, [show_question_box, data, node_question_type]);

  const [prompt_node_submit, set_prompt_node_submit] = React.useState();
  React.useEffect(() => {
    if (!prompt_node_submit) return;

    if (isReadOnly) {
      dispatch({
        type: FLOW_MODAL,
        value: {
          visible: true,
          action: 'copy_to_editable'
        }
      })

      return;
    }

    if (open_sub_menu(itemTargeted)) {
      return;
    }

    const itemsCount = sub_menu_visible ? sub_menu_items?.length : filteredAssistantItems.length;

    if (prompt_node_submit.user_behavior === 'click_submit' || itemTargeted < 0 || itemTargeted >= itemsCount) {
      if (prompt_node_submit.prompt?.trim()) {
        userInitPrompt(prompt_node_submit.prompt, prompt_node_submit.options);
      } else if (prompt_node_submit.question?.trim()) {
        aiItemClicked({
          item: { action: show_question_box === 'add' && (data.ai_action === 'flow_brainstorming' && 'brainstorming_perspective' || data.ai_action === 'flow_mindmap' && 'mindmap_primary_branch') || ['ask', 'ask_question', 'ask_question_or_choose_skill'].includes(node_question_type) && 'query' || node_question_type === 'brainstorming' && 'dynamic_brainstorming' || node_question_type === 'todos' && 'flow_task_breakdown' || node_question_type || 'query' },
          queryType: show_question_box === 'add' && (data.ai_action === 'flow_brainstorming' && 'perspective' || data.ai_action === 'flow_mindmap' && 'mindmap_primary_branch') || ((!node_question_type || node_question_type === 'ask_question_or_choose_skill') ? 'ask_question' : node_question_type),
          userInput: prompt_node_submit.question,
          context_node_id: data.ai_action === 'flow_mindmap' && data.context_node_id
        });
      }
    } else {
      itemSelected({ order: itemTargeted, callback: () => setItemTargeted(0) });
      setItemTargeted(-1);
    }
  }, [prompt_node_submit?.trigger])

  const regenerate = React.useCallback((model) => {
    const action = node.data.ai_action;

    let item = ai_items.find(it => it.action == action) || groupedAssistantItems.find(it => it.action == action);

    const selected_model = get_model_by_id(model);
    if (item?.llms && !item.llms.includes(selected_model?.value)) {
      dispatch({
        type: FLOW_MODAL,
        value: {
          visible: true,
          action: 'action_requires_specific_models',
          params: {
            required_models: item.llms
          }
        }
      })
      return;
    }

    let newNode = {
      id: new Date().getTime() + '',
      position: getNewSubNodePosition(node, getNode, getNodeEdges),
      data: {
        ...node.data,
        ai_model: model,

        related_questions_topics: null,
        reflect_and_improve: false,
        aigc_triggered: false,
        savingTriggered: undefined,
        content: '',
        todos: null,
        items: null,
      },
      type: 'ai_node'
    }

    if (node.data.context_node_id) {
      addSubNode(node.data.context_node_id, newNode);
    } else {
      addNode(newNode)
    }
  }, [node, getNewSubNodePosition, get_model_by_id])

  const retry = React.useCallback(() => {
    updateNodeData(nodeId, {
      reflect_and_improve: false,
      aigc_triggered: false,
      savingTriggered: undefined,
      content: '',
      todos: null,
      items: null,
    });

    setAiResponse(null);
    setErrMsg(null);
  }, [nodeId, data.form, updateNodeData])

  const savePrompt = React.useCallback(() => {
    let prompt = data.userInput;
    if (data.queryType === 'ask_question') {
      prompt = prompt + '\n\n[Given text]: {{selected_text}}';
    } else if (!prompt) {
      prompt = data.form?.userInput;
    }

    const item = {
      prompt,
      args: data.form?.args?.map(arg => {
        if (arg.type === 'select') {
          arg.enable_user_input = !!arg.options?.find(option => !option.value);
        }

        return arg;
      })
    };

    dispatch({
      type: PROMPT_DIALOG,
      value: {
        visible: true,
        data: item,
        mode: 'new'
      },
    })
  }, [form, data])

  const reflect = React.useCallback((refresh) => {
    if (!data.reflect || refresh) {
      if (data.form) {
        setForm(data.form);
      }

      updateNodeData(nodeId, {
        reflect_and_improve: 'reflect',
        reflect: null,
        aigc_triggered: false,
        savingTriggered: undefined,
      });
    }

    set_show_action_box(prevState => (prevState === 'reflect' && !refresh) ? null : 'reflect');
  }, [nodeId, data.reflect, data.form, updateNodeData])

  const improve = React.useCallback(() => {
    if (data.form) {
      setForm(data.form);
    }

    updateNodeData(nodeId, {
      reflect_and_improve: 'improve',
      aigc_triggered: false,
      savingTriggered: undefined,
      aigc_done: false
    });

  }, [nodeId, data.form])

  const moveElementToHeader = React.useCallback((array, pos) => {
    if (array.length < pos + 1) return;

    let element = array.splice(pos, 1)[0];
    array.unshift(element);
  }, [])

  const groupMenuItems = React.useCallback((items) => {
    let grouped = Array.from({ length: Math.max(...items.map(item => item.group)) + 1 }, () => []);

    items.forEach(item => {
      if (typeof item.group == 'number') {
        // let pos = AI_MENU_GROUP_POSITION.find(it => it.group == item.group)?.position;
        let pos = item.group;
        // if (pos === undefined) {
        //   pos = item.group;
        // }
        grouped[pos].push({ ...item });
      }
    });

    moveElementToHeader(grouped, 3);

    let index = 0;
    for (let i = 0; i < grouped.length; i++) {
      const group = grouped[i];
      for (let j = 0; j < group.length; j++) {
        const item = group[j];
        item.order = index;
        index++;
      }
    }

    grouped = grouped.flatMap((group, i) => {
      if (!group || group.length === 0) {
        return [];
      }

      const item_group = assistant_items_groups?.find(item => item.group === group[0].group);

      if (item_group) {
        group.unshift({
          type: 'groupName',
          label: item_group.label,
          group: i
        })
      }

      if (item_group && i > 0) {
        group.unshift({
          type: 'divider'
        })
      }

      return group;
    });

    return grouped;
  }, [assistant_items_groups]);

  React.useEffect(() => {
    const value = (question || nodeType === 'prompt' && userInput || '')?.replace(/<br\s*\/?>\s*$/, '');
    setSearchText(value);
  }, [question, userInput])

  React.useEffect(() => {
    if (!extendedAssistantItems) return;

    let itemsFiltered = extendedAssistantItems.filter(item => {
      if (!searchText) return true;

      return item.label?.toLowerCase().includes(searchText?.toLowerCase()) ||
        typeof item.action === 'string' && item.type !== 'user_installed' && item.action.toLowerCase().includes(searchText?.toLowerCase());
    });

    setFilteredAssistantItems(itemsFiltered);

    let itemSelected = 0;
    if (itemsFiltered.length == 0) {
      itemSelected = -1;
    }

    setItemTargeted(itemSelected);
  }, [extendedAssistantItems, searchText]);

  React.useEffect(() => {
    if (!filteredAssistantItems) return;

    setGroupedAssistantItems(groupMenuItems(filteredAssistantItems));
  }, [filteredAssistantItems])

  React.useLayoutEffect(() => {
    setTimeout(() => form_input_ref?.current?.focus(), 100);
  }, [form_input_ref?.current])

  const gotoMarket = () => {
    // handleClose(true);
    dispatch({ type: SETTINGS_DIALOG, value: { visible: true, page: 'service_subscribe', app: 'flow' } });
    // window.open('/#/aiplans', '_blank')
  }

  const inviteFriends = () => {
    // handleClose(true);
    dispatch({ type: INVITE_FRIENDS_DIALOG, value: { visible: true } });
  }

  const gotoAPISetting = React.useCallback(() => {
    // handleClose(true);
    dispatch({
      type: LLM_API_KEY_MODAL,
      value: {
        visible: true
      }
    })
  }, []);

  const gotoPrompts = React.useCallback(() => {
    dispatch({ type: SETTINGS_DIALOG, value: { visible: true, page: 'prompts' } });
  }, []);

  const [buttonHovered, setButtonHovered] = React.useState();
  const renderSubTaskButton = React.useCallback((subTask) => {
    return <>
      {
        subTasking != subTask.action &&
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            className='hoverButton'
            style={{
              paddingLeft: '6px', paddingRight: '6px',
              paddingTop: '1px', paddingBottom: '1px',
              borderRadius: '6px',
              fontSize: '14px',
              cursor: 'pointer', whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', flexDirection: 'row',
              backgroundColor: subTasking ? '#ddd' : (buttonHovered ? color_theme.border : color_theme.content_bg),
              color: subTasking ? 'white' : (buttonHovered ? 'white' : color_theme.border),
              // border: subTasking ? undefined : `solid 1px ${color_theme.border}`
            }}
            onMouseEnter={() => setButtonHovered(true)}
            onMouseLeave={() => setButtonHovered(false)}
            onClick={() => {
              if (subTasking) return;

              const validArgs = form.args.filter(arg => !arg.disabled);
              const required_args = validArgs?.filter(arg => arg.required);
              if (required_args?.length > 0) {
                for (const arg of required_args) {
                  if (!arg.value?.trim()) {
                    return showErrorMsg('missing_required_data');
                  }
                }
              } else if (validArgs?.length > 0) {
                if (selectable_context_option == 'none' && !validArgs.find(arg => !!arg.value?.trim())) {
                  return showErrorMsg('missing_one_data');
                }
              }

              aiAction({ selectedText, action: form.item?.action, subTask, aiResponse })
            }}>
            {subTask.label}
            <div style={{ marginLeft: '6px', alignItems: 'center', display: 'flex' }} >
              <Magic size={16} />
            </div>
          </div>
        </div>
      }
      {
        subTasking == subTask.action && <>
          <CircularProgress size={18} />
          <span style={{ marginLeft: '8px', marginRight: '8px', fontSize: '14px', color: 'gray', whiteSpace: 'nowrap' }}>
            {intl.formatMessage({ id: 'askAI_doing' })}
          </span>
        </>
      }
    </>
  }, [color_theme, intl, subTasking, form, buttonHovered]);

  const ungroup = React.useCallback(() => {

    const updatedNodes = [
      ...nodes.map(n => {
        if (n.parentId != nodeId) return n;

        return {
          ...n,
          parentId: undefined,
          extent: undefined,
          position: {
            x: node.position.x + n.position.x,
            y: node.position.y + n.position.y
          }
        }
      }),
    ].filter(n => n.id != nodeId);

    setNodes(updatedNodes);
  }, [node, nodes, setNodes]);

  const nodeRef = React.useRef();
  const [resized, setResized] = React.useState();
  const [resizedSize, setResizedSize] = React.useState();

  React.useEffect(() => {
    if (nodeRef?.current) {
      nodeRef.current.parentElement.style.height = 'fit-content';
    }
  }, [nodeRef?.current])

  React.useEffect(() => {
    if (nodeType != 'image' || !imgRef?.current || !nodeRef?.current?.offsetWidth) return;

    imgRef.current.width = nodeRef?.current?.offsetWidth - 2;
  }, [nodeRef?.current?.offsetWidth])

  const searchResult = React.useMemo(() => data.queryType === 'search' && data.context?.length > 0 &&
    <div style={{
      margin: '12px',
      marginTop: 3,
      marginBottom: 7,
    }}>
      <div style={{
        color: 'black',
        fontWeight: 'bold',
        fontSize: 15,
      }}>
        {intl.formatMessage({ id: 'search_results' })}:
      </div>
      {
        data.context.map((item, index) => (<div
          key={index + ''}
          style={{
            color: 'dodgerblue',
            cursor: 'pointer',
            marginTop: 5,
            fontSize: 14
          }}

          onClick={() => {
            if (item.content) {
              aiItemClicked({
                item: { action: 'summary' },
                queryType: 'link',
                context: {
                  title: item.title,
                  content: item.content,
                  url: item.url
                }
              })
            } else {
              aiItemClicked({
                item: {},
                nodeType: 'prompt',
                queryType: 'link',
                userInput: item.url,
              })
            }
          }}
        >
          {`[${index}]: ${item.title}`}
        </div>))
      }
    </div>
    , [data.context, data.queryType])

  const AIMenu = React.useMemo(() => {
    if (!groupedAssistantItems?.length) {
      return null;
    }

    return <div
      className='nowheel'
      style={{ ...styles.container, borderColor: color_theme.border, backgroundColor: color_theme.content_bg, width: '340px', overflow: 'hidden' }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div
        className='inner-wrapper'
        style={{
          maxHeight: '300px',
        }}>
        {
          groupedAssistantItems?.map((item, index) => {
            return <div
              ref={(ref) => {
                elmRefs.current = { ...elmRefs.current, [item.order]: ref };
              }}
              key={index + ''}
            >
              {
                item.type === 'groupName' &&
                <div style={{ fontSize: 13, color: 'gray', padding: '6px', display: 'flex', flexDirection: 'row' }}>
                  {item.label}
                  {
                    item.type === 'user_installed' &&
                    <div style={{
                      paddingLeft: '6px', paddingRight: '6px', cursor: 'pointer',
                      display: 'flex', justifyContent: 'flex-end',
                      flex: 1,
                      whiteSpace: 'nowrap',
                    }} onClick={() => gotoPrompts()}>{intl.formatMessage({ id: 'CRUD' })}</div>
                  }
                </div>
              }
              {
                item.type === 'divider' && !!index &&
                <div className='fill-available' style={{ marginLeft: 3, marginRight: 3, height: 1, backgroundColor: color_theme.title_bg }} />
              }
              {
                !['groupName', 'divider'].includes(item.type) &&
                <div
                  key={index + ''}
                  onClick={() => itemSelected({ order: item.order, using_model: using_model })}
                  className='hoverStand'
                  style={{
                    backgroundColor: item.order === itemTargeted ? color_theme.title_bg : undefined,
                    padding: '5px', paddingLeft: '12px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                  }}
                >
                  <div style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: 'block',
                  }}>
                    {item.label}
                  </div>
                  {item.order === itemTargeted && <KeyboardReturn size={20} style={{ cursor: 'pointer', color: color_theme.border }} />}
                </div>
              }
            </div>
          })
        }
      </div>
    </div>
  }, [color_theme, groupedAssistantItems, elmRefs, itemTargeted, itemSelected])

  const defaultWidth = React.useMemo(() => nodeType === 'slides' || data.ai_action === 'slideshow' ? 480 : 340, []);
  return (
    <div
      ref={nodeRef}
      className='node'
      style={nodeType == 'group' ? {
        display: 'contents'
      } : {
        backgroundColor: color_theme.content_bg,
        borderColor: color_theme.border,
        boxShadow: `0px 0px 6px #ccc`,
        borderRadius: nodeType == 'note' ? 0 : undefined,
        width: resized ? undefined : (node?.width || defaultWidth),
        minHeight: data.ai_action === 'slideshow' ? defaultWidth * 9 / 16 : undefined,
        maxHeight: data.minimized ? 100 : undefined,
        overflowY: data.minimized ? 'clip' : undefined,
        pointerEvents: 'all'
      }}
      onKeyPress={onKeyPress}
      onMouseEnter={() => set_aigc_hovered(true)}
      onMouseLeave={() => set_aigc_hovered(false)}
    >
      <NodeToolbar
        position={Position.Top}
        style={{
          border: `1px solid ${color_theme.border}`,
          borderRadius: 4,
          backgroundColor: color_theme.content_bg,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: `0px 0px 6px #ccc`,
        }}
      >
        {
          !['image', 'prompt'].includes(data.nodeType) && (data.content || data.nodeType == 'slides') && !['flow_mindmap', 'flow_brainstorming'].includes(data.ai_action) && !data.items && !data.todos &&
          <Tooltip title={intl.formatMessage({ id: 'generate_mindmap' })} placement="top">
            <div
              className='hoverStand'
              style={{ color: color_theme.border, ...styles.toolbarIcon }}
              onClick={(event) => {
                if (isReadOnly) {
                  dispatch({
                    type: FLOW_MODAL,
                    value: {
                      visible: true,
                      action: 'copy_to_editable'
                    }
                  })

                  return;
                }

                aiItemClicked({ item: { action: 'flow_mindmap', label: 'Mindmap' } })
              }}
            >
              <MindMap size={21} />
            </div>
          </Tooltip>
        }

        {
          !['image', 'prompt'].includes(data.nodeType) &&
          <>
          <Tooltip title={intl.formatMessage({ id: 'infograph_tips' })} placement="top">
            <div
              className='hoverStand'
              style={{ color: color_theme.border, ...styles.toolbarIcon }}
              onClick={(event) => {
                if (isReadOnly) {
                  dispatch({
                    type: FLOW_MODAL,
                    value: {
                      visible: true,
                      action: 'copy_to_editable'
                    }
                  })

                  return;
                }

                const item = ai_items.find(item => item.action == 'flow_infograph');
                if (!item) {
                  return;
                }

                aiItemClicked({ item })
              }}
            >
              <BubbleChart size={21} />
            </div>
          </Tooltip>
          <Tooltip title={intl.formatMessage({ id: 'summarize_tips' })} placement="top">
          <div
            className='hoverStand'
            style={{ color: color_theme.border, ...styles.toolbarIcon }}
            onClick={(event) => {
              if (isReadOnly) {
                dispatch({
                  type: FLOW_MODAL,
                  value: {
                    visible: true,
                    action: 'copy_to_editable'
                  }
                })

                return;
              }

              aiItemClicked({ item: { action: 'summary', label: intl.formatMessage({ id: 'summary' }) } })
            }}
          >
            <TextParagraph size={22} />
          </div>
          </Tooltip>
        </>
        }

        {
          ['image'].includes(data.nodeType) && <Tooltip title={intl.formatMessage({ id: 'describe_image' })} placement="top">
            <div
              className='hoverStand'
              style={{ color: color_theme.border, ...styles.toolbarIcon }}
              onClick={(event) => {
                if (isReadOnly) {
                  dispatch({
                    type: FLOW_MODAL,
                    value: {
                      visible: true,
                      action: 'copy_to_editable'
                    }
                  })

                  return;
                }

                aiItemClicked({ item: { action: 'describe_image', label: intl.formatMessage({ id: 'describe_image' }) } })
              }}
            >
              <ImageEdit size={22} />
            </div>
          </Tooltip>
        }

        {
          !['prompt'].includes(data.nodeType) &&
          <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
            <Dot size={16} color='#aaa' />
          </div>
        }

        {
          !isReadOnly && !node.parentId && !loading && data.ai_action && nodeType === 'aigc' && !['brainstorming_insights'].includes(data.queryType) &&
          <RegenerateMenu
            data={data}
            color_theme={color_theme}
            onRegenerate={regenerate}
            iconStyle={styles.toolbarIcon}
          />
        }

        {
          !isReadOnly && (data.content || data.todos || data.items) && !node.parentId && !loading && data.ai_action && nodeType === 'aigc' && !['brainstorming_insights'].includes(data.queryType) &&
          <Tooltip
            title={intl.formatMessage({ id: 'reflect_and_improve' })}
            placement='top'
          >
            <div
              className='hoverStand'
              style={styles.toolbarIcon}
              onClick={() => reflect()}
            >
              <Refine color='#666' size={19} />
            </div>
          </Tooltip>
        }

        {
          nodeType !== 'image' &&
          // (['breakdown', 'brainstorming_perspective'].includes(data.ai_action)) &&
          data.items &&
          !isReadOnly &&
          <Tooltip title={intl.formatMessage({ id: 'to_todolist' })} placement="top">
            <div
              className='hoverStand'
              style={styles.toolbarIcon}
              onClick={(event) => {
                const node = getNode(nodeId);

                if (!data.items) {
                  return;
                }

                const newNode = copyNodeItemsToTaskList(node);

                addNode(newNode);

                setSavingTrigger(Math.random());
              }}
            >
              <ListTask color='#555' size={21} />
            </div>
          </Tooltip>
        }
        {
          !['image', 'group', 'slides'].includes(nodeType) &&
          !isReadOnly &&
          <Tooltip title={intl.formatMessage({ id: 'to_note' })} placement="top">
            <div
              className='hoverStand'
              style={styles.toolbarIcon}
              onClick={(event) => handleCopyToNote(event)}
            >
              <Note color='#555' size={21} />
            </div>
          </Tooltip>
        }
        {
          nodeType == 'image' &&
          <Tooltip title={intl.formatMessage({ id: 'add_caption' })} placement="top">
            <div
              className='hoverStand'
              style={styles.toolbarIcon}
              onClick={() => {
                dispatch({
                  type: FLOW_INPUT_MODAL,
                  value: {
                    id: 'image_caption',
                    value: data.content?.caption,
                    nodeId,
                    multiline: true,
                    visible: true
                  }
                })
              }}
            >
              <ImageAltText color='#555' size={21} />
            </div>
          </Tooltip>
        }

        {
          !['group', 'slides'].includes(nodeType) &&
          <Tooltip title={intl.formatMessage({ id: copied ? 'copied' : 'copy_content' })} placement="top">
            <div
              className='hoverStand'
              style={styles.toolbarIcon}
              onClick={(event) => copy()}
            >
              {copied && <ClipboardCheck size={18} />}
              {!copied && <Clipboard color='#555' size={18} />}
            </div>
          </Tooltip>
        }

        <ColorMenu
          iconStyle={styles.toolbarIcon}
          onSubmit={(theme_id) => {
            updateNodeData(nodeId, {
              color_theme: theme_id
            })
          }}
        >
          <Color color='#555' size={20} />
        </ColorMenu>

        {
          nodeType == 'slides' &&
          <DownloadButton
            iconStyle={styles.toolbarIcon}
            node={getNode(nodeId)}
            title={title || 'ai_flow'}
            shareUrl={shareUrl}
            imageType={nodeType == 'group' ? 'group' : 'node'}
            isGroup={nodeType == 'group'}
            onClick={() => set_aigc_hovered(false)}
          />
        }

        {
          nodeType !== 'slides' &&
          <NodeExportMenu
            node={node}
            shareUrl={shareUrl}
            form={form}
            getNodeContent={getNodeContent}
            onClick={() => set_aigc_hovered(false)}
            generateSlides={generateSlides}
            iconStyle={styles.toolbarIcon}
          >
            <BoxArrowRight color='#666' size={21} />
          </NodeExportMenu>
        }

        {/* {
          nodeType === 'group' &&
          <Tooltip title={intl.formatMessage({ id: 'ungroup_nodes' })} placement="top">
            <div
              className='hoverStand'
              style={styles.toolbarIcon}
              onClick={ungroup}
            >
              <GroupDismiss color='#666' size={22} />
            </div>
          </Tooltip>
        } */}

        {
          isMobile &&
          <Tooltip title={intl.formatMessage({ id: 'delete' })} placement="top">
            <div
              className='hoverStand'
              style={styles.toolbarIcon}
              onClick={(event) => {
                setSavingTrigger(Math.random());
                deleteNode(nodeId);
              }}
            >
              <Close color='#555' size={18} />
            </div>
          </Tooltip>
        }
      </NodeToolbar>

      {
        (['aigc', 'image', 'note', 'group', 'slides', 'funblocks_doc'].includes(nodeType) || nodeType === 'prompt' && ['search', 'ask'].includes(data.queryType)) &&
        <>
          <Tooltip
            title={intl.formatMessage({ id: data.queryType === 'search' && 'optimize_search' || data.queryType === 'ask' && 'optimize_question' || !(data.related_questions_topics || data.query_optimize) && 'ai_insights' || (show_action_box ? 'hide_action_box' : 'tell_me_more') })}
            placement='bottom'
          >
            <div
              className='circle-button'
              style={{
                position: 'absolute',
                top: action_box_position == Position.Bottom ? undefined : '50%',
                right: action_box_position == Position.Right ? -15 : undefined,
                left: action_box_position == Position.Left && -15 || action_box_position == Position.Bottom && '50%' || undefined,
                bottom: action_box_position == Position.Bottom ? -15 : undefined,
                transform: action_box_position == Position.Bottom ? 'translateX(-50%)' : 'translateY(-50%)',
                display: show_action_box || !errMsg && !['breakdown', 'flow_task_breakdown', 'flow_mindmap', 'mindmap_primary_branch', 'brainstorming_perspective', 'flow_brainstorming'].includes(data.ai_action) && !['brainstorming_insights', 'todos'].includes(data.queryType) && (getNodeContent(getNode(nodeId), 'content') || ['group'].includes(nodeType)) && aigc_hovered ? 'flex' : 'none',
                borderColor: color_theme.border,
                color: color_theme.border,
                backgroundColor: color_theme.content_bg,
                zIndex: 9999,
              }}
              onClick={() => {
                if (!loading && !show_action_box && !data.related_questions_topics?.related_topics?.length && !data.related_questions_topics?.related_questions?.length && !data.query_optimize?.optimized?.length) {
                  updateNodeData(nodeId, {
                    backgroundTasks: { ...data.backgroundTasks, related_questions_topics: undefined, query_optimize: undefined, trigger: Math.random() },
                    // aigc_done: true,
                    showActionBox: true,
                    reflect_and_improve: false
                  })
                  return;
                }

                set_show_action_box(prevState => !!prevState ? null : 'tell_more');

                if (data.showActionBox) {
                  updateNodeData(nodeId, { showActionBox: false });
                }
              }}
            >
              <div
                className='circle-button-borderless'
              >
                {!loading && show_action_box && <Close size={18} />}
                {!loading && (!!data.related_questions_topics?.related_topics?.length || !!data.related_questions_topics?.related_questions?.length || !!data.query_optimize?.optimized?.length) && !show_action_box && <Add size={20} />}
                {!loading && !show_action_box && !data.related_questions_topics?.related_topics?.length && !data.related_questions_topics?.related_questions?.length && !data.query_optimize?.optimized?.length && <LightningCharge size={20} />}
                {loading && doing?.backgroundTask && <CircularProgress size={16} />}
              </div>
            </div>
          </Tooltip>
          {
            ['aigc', 'image', 'note', 'group', 'slides', 'funblocks_doc'].includes(nodeType) &&
            <div
              className='circle-button'
              style={{
                position: 'absolute',
                width: 'fit-content',
                bottom: -15,
                left: '50%',
                transform: 'translateX(-50%)',
                display: show_question_box || !errMsg && !form
                  && (['flow_brainstorming', 'brainstorming_perspective', 'flow_mindmap', 'mindmap_primary_branch', 'breakdown'].includes(data.ai_action)
                    || ['brainstorming_insights', 'todos'].includes(data.queryType) || getNodeContent(getNode(nodeId), 'content') || nodeType === 'group')
                  && aigc_hovered ? 'flex' : 'none',
                borderColor: color_theme.border,
                color: color_theme.border,
                backgroundColor: color_theme.content_bg,
                zIndex: 9999,
              }}
            >
              <Tooltip
                title={intl.formatMessage({ id: 'flow_ask_question_or_choose_skill_placeholder' })}
                placement='bottom'
              >
                <div
                  className='circle-button-borderless'
                  onClick={() => {
                    set_show_question_box('message');
                  }}
                >
                  <Message size={16} />
                </div>
              </Tooltip>
              <Tooltip
                title={intl.formatMessage({ id: data.ai_action === 'flow_brainstorming' && 'new_perspective' || data.ai_action === 'brainstorming_perspective' && 'new_idea' || data.ai_action === 'flow_mindmap' && 'mindmap_primary_branch' || ['breakdown', 'mindmap_primary_branch'].includes(data.ai_action) && 'new_sub_topic' || data.queryType === 'brainstorming_insights' && 'new_insight' || data.queryType === 'todos' && 'new_task' || 'ask_question' })}
                placement='bottom'
              >
                <div className='circle-button-borderless'
                  style={{
                    display: !errMsg && (['flow_brainstorming', 'brainstorming_perspective', 'flow_mindmap', 'mindmap_primary_branch', 'breakdown'].includes(data.ai_action) || ['brainstorming_insights', 'todos'].includes(data.queryType)) || data.todos ? 'flex' : 'none',
                  }}
                  onClick={() => {
                    set_show_question_box('add');
                  }}
                >
                  <Add size={20} />
                </div>
              </Tooltip>
            </div>
          }
          {
            show_question_box &&
            <div
              className='fill-available'
              style={{
                position: 'absolute',
                // left: '50%',
                // transform: 'translateX(-50%)' ,
                left: 0,
                top: 'calc(100% + 20px)',
                zIndex: 9,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <div
                className='fill-available'
                style={{
                  maxWidth: 640
                }}>
                <div style={{
                  border: `1px solid ${color_theme.border}`,
                  borderRadius: '4px',
                  boxShadow: '0px 0px 8px #bbb',
                }}>
                  <PromptNode
                    nodeId={nodeId}
                    queryTypeOptions={show_question_box == 'message' && ['dynamic', 'brainstorming', 'breakdown', 'todos', 'ask']}
                    onChange={(value) => setQuestion(value)}
                    onKeyDown={onKeyDown}
                    handleConfirm={nodeQuestionConfirm}
                    queryType={show_question_box == 'message' && (((node_question_type || 'ask_question_or_choose_skill') === 'ask_question_or_choose_skill') && !groupedAssistantItems?.length ? 'ask' : (node_question_type || 'ask_question_or_choose_skill')) || data.ai_action === 'flow_brainstorming' && 'perspective' || ['brainstorming_perspective'].includes(data.ai_action) && 'idea' || data.ai_action === 'flow_mindmap' && 'mindmap_primary_branch' || ['breakdown', 'mindmap_primary_branch'].includes(data.ai_action) && 'new_sub_topic' || data.queryType === 'todos' && 'new_task' || data.queryType === 'brainstorming_insights' && 'new_insight'}
                    onQueryTypeChange={(queryType) => {
                      set_node_question_type(queryType === 'ask' && 'ask_question_or_choose_skill' || queryType)
                    }}
                    color_theme={color_theme}
                  // loading={loading}
                  />
                </div>
                {show_question_box == 'message' && (!node_question_type || node_question_type === 'ask_question_or_choose_skill') && !isReadOnly && AIMenu}
              </div>
            </div>
          }
          {
            selectedArtifact &&
            <Artifact
              offsetTop={artifactOffsetTop}
              selectedArtifact={selectedArtifact}
              setSelectedArtifact={setSelectedArtifact}
              aigc_hovered={aigc_hovered}
              color_theme={color_theme}
            />
          }
          {
            // (show_action_box && (!!data.related_questions_topics || !!data.query_optimize) || show_action_box === 'reflect') &&
            show_action_box &&
            <div
              className={action_box_position == Position.Bottom ? 'fill-available' : undefined}
              style={{
                position: 'absolute',
                left: 'calc(100% + 20px)',
                right: action_box_position == Position.Left ? 'calc(100% + 20px)' : undefined,
                left: show_action_box === 'reflect' || action_box_position == Position.Right ? 'calc(100% + 20px)' : undefined,
                top: show_action_box === 'reflect' && '0px' || (action_box_position == Position.Bottom ? 'calc(100% + 20px)' : '50%'),
                width: action_box_position == Position.Bottom ? undefined : 320,
                borderRadius: 4,
                border: `1px solid ${color_theme.border}`,
                backgroundColor: color_theme.content_bg,
                boxShadow: '0px 0px 8px #bbb',
                transform: show_action_box === 'reflect' || action_box_position == Position.Bottom ? undefined : 'translateY(-50%)',

              }}
            >
              <div style={{
                padding: 14,
                paddingRight: 9,
                paddingBottom: 4,
                display: 'flex',
                flexDirection: 'column',
                position: 'relative'
              }}>
                <div style={{
                  color: '#999',
                  marginBottom: 10
                }}>
                  {intl.formatMessage({ id: show_action_box == 'reflect' && 'reflect_and_improve' || nodeType === 'prompt' && 'optimized_query' || ['brainstorming_perspective'].includes(data.ai_action) && 'selectable_ideas' || 'tell_me_more' })}
                </div>
                {
                  show_action_box === 'tell_more' &&
                  data.related_questions_topics?.related_questions &&
                  <AIActionList
                    nodeId={nodeId}
                    listType='related_questions'
                    list={data.related_questions_topics.related_questions}
                    queryType={'tell_more'}
                    aiItemClicked={actionItemClicked}
                    color_start={1}
                  />
                }
                {
                  show_action_box === 'tell_more' &&
                  data.related_questions_topics?.related_topics &&
                  <AIActionList
                    nodeId={nodeId}
                    listType='related_topics'
                    list={data.related_questions_topics.related_topics}
                    queryType={'tell_more'}
                    aiItemClicked={actionItemClicked}
                    color_start={4}
                  />
                }
                {
                  show_action_box === 'reflect' &&
                  data.reflect?.improvement_plan &&
                  <>
                    <div style={{
                      fontSize: 14,
                      color: '#666'
                    }}>
                      {intl.formatMessage({ id: "reflect" })}
                    </div>
                    {
                      !!data.reflect.evaluation &&
                      <MarkdownRenderer content={data.reflect.evaluation} />
                    }
                    <div
                      className='fill-available'
                      style={{
                        fontSize: 14,
                        color: '#666',
                        marginTop: 6,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}>
                      {intl.formatMessage({ id: "improvement_plan" })}
                    </div>
                    <AIActionList
                      nodeId={nodeId}
                      listType='improvement_plan'
                      list={data.reflect.improvement_plan}
                      onDeleteItem={(index) => {
                        const items = [...data.reflect.improvement_plan];
                        items.splice(index, 1);
                        updateNodeData(nodeId, {
                          reflect: {
                            ...data.reflect,
                            improvement_plan: items
                          }
                        })
                      }}
                    />

                    <div
                      className='fill-available'
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                      }}
                    >
                      <div
                        className={'hover_shadow'}
                        style={{
                          padding: 10,
                          paddingTop: 3,
                          paddingBottom: 4,
                          margin: 8,
                          marginLeft: 2,
                          marginRight: 10,
                          width: 'fit-content',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          columnGap: 6,
                          color: color_theme.border,
                          cursor: 'pointer',
                          border: `1px solid ${color_theme.border}`,
                          borderRadius: '14px',
                          whiteSpace: 'nowrap',
                          fontSize: '14px'
                        }}

                        onClick={() => {
                          set_show_add_improve_plan_box(true);
                        }}
                      >
                        {intl.formatMessage({ id: 'add_improvement_plan' })}
                      </div>
                      <div
                        className={'hover_shadow'}
                        style={{
                          // boxShadow: '0px 0px 8px #bbb',
                          padding: 10,
                          paddingTop: 3,
                          paddingBottom: 4,
                          margin: 8,
                          marginRight: 10,
                          width: 'fit-content',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          columnGap: 6,
                          color: 'white',
                          cursor: 'pointer',
                          backgroundColor: color_theme.border,
                          borderRadius: '14px',
                          whiteSpace: 'nowrap',
                          fontSize: '14px'
                        }}

                        onClick={improve}
                      >
                        {intl.formatMessage({ id: 'improve_now' })}
                        {loading && <CircularProgress size={18} />}
                      </div>
                    </div>
                  </>
                }
                {
                  show_action_box === 'tell_more' &&
                  data.query_optimize?.optimized &&
                  <>
                    <AIActionList
                      nodeId={nodeId}
                      listType={'optimized'}
                      list={data.query_optimize.optimized}
                      queryType={data.queryType}
                      ai_generated={true}
                      nodeType={'prompt'}
                      aiItemClicked={actionItemClicked}
                    />

                    {
                      data.query_optimize.analysis &&
                      <>
                        <div style={{ color: '#999', marginTop: 6, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          {intl.formatMessage({ id: 'query_analysis' })}
                          <Tooltip
                            title={intl.formatMessage({ id: data.query_optimize.analysis_hide ? 'show_detail' : 'hide_detail' })}
                            placement='top'
                          >
                            <div
                              className='transparent-background'
                              style={{
                                width: 24,
                                height: 24
                              }}
                              onClick={() => {
                                updateNodeData(nodeId, {
                                  query_optimize: {
                                    ...data.query_optimize,
                                    analysis_hide: !data.query_optimize.analysis_hide
                                  }
                                })
                              }}
                            >
                              {
                                data.query_optimize.analysis_hide &&
                                <ArrowDownS size={20} color='#444' />

                              }
                              {
                                !data.query_optimize.analysis_hide &&
                                <ArrowUpS size={20} color='#444' />
                              }
                            </div>
                          </Tooltip>
                        </div>
                        {
                          !data.query_optimize.analysis_hide &&
                          <MarkdownRenderer content={data.query_optimize.analysis} />
                        }
                      </>
                    }
                  </>
                }

                {
                  loading && doing?.backgroundTask &&
                  <div style={{ marginRight: '6px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: 100 }}>
                    <CircularProgress size={18} />
                    <span style={{ marginLeft: '8px', marginRight: '8px', fontSize: '14px', color: 'gray', whiteSpace: 'nowrap' }}>
                      {intl.formatMessage({ id: 'askAI_doing' })}
                    </span>
                  </div>
                }

                <Tooltip
                  title={intl.formatMessage({ id: 'get_more' })}
                  placement='bottom'
                >
                  <div
                    className='transparent-background'
                    style={{
                      padding: 4,
                      position: 'absolute',
                      top: 6,
                      right: 6
                    }}
                    onClick={() => {
                      if (loading && doing?.backgroundTask) return;

                      if (show_action_box === 'reflect') {
                        reflect(true);
                      } else if (['brainstorming_perspective'].includes(data.ai_action)) {
                        updateNodeData(nodeId, {
                          aigc_triggered: false,
                          savingTriggered: undefined,
                          reflect_and_improve: false
                        });
                      } else {
                        updateNodeData(nodeId, {
                          savingTriggered: undefined,
                          related_questions_topics: undefined,
                          optimized_query: undefined,
                          reflect_and_improve: false,
                          // aigc_done: true,
                          backgroundTasks: { ...data.backgroundTasks, related_questions_topics: undefined, query_optimize: undefined, trigger: Math.random() }
                        });
                      }
                    }}
                  >
                    <Refresh size={24} color={loading && doing?.backgroundTask ? 'gray' : color_theme.border} />
                  </div>
                </Tooltip>

                {
                  show_add_improve_plan_box &&
                  <div
                    className='fill-available'
                    style={{
                      border: `1px solid ${color_theme.border}`,
                      borderRadius: '4px',
                      position: 'absolute',
                      boxShadow: '0px 0px 8px #bbb',
                      left: 0,
                      top: 'calc(100% + 20px)',
                      zIndex: 9
                    }}
                  >
                    <PromptNode
                      nodeId={nodeId}
                      handleConfirm={(_, improvement) => {
                        if (!improvement?.trim()) return;

                        let items = [...(data.reflect.improvement_plan || [])];
                        items.push(improvement)

                        updateNodeData(nodeId, {
                          reflect: {
                            ...data.reflect,
                            improvement_plan: items
                          }
                        })
                      }}
                      queryType={'add_improve_plan'}
                      color_theme={color_theme}
                    // loading={loading}
                    />
                  </div>
                }
              </div>
            </div>
          }
        </>
      }

      <Handle type="target" position={Position.Top} id="TT" />
      <Handle type="target" position={Position.Bottom} id="BT" />
      <Handle type="target" position={Position.Left} id='LT' />
      <Handle type="target" position={Position.Right} id="RT" />
      <Handle type="source" position={Position.Top} id="a" />
      <Handle type="source" position={Position.Right} id="b" />
      <Handle type="source" position={Position.Bottom} id="c" />
      <Handle type="source" position={Position.Left} id="d" />

      <NodeResizer
        isVisible={true}
        minWidth={180}
        minHeight={100}
        lineStyle={{
          width: 10
        }}
        onResize={(event, resizeParams) => {
          if (nodeType === 'group') {
            updateNode({
              ...node,
              style: {
                ...node.style,
                width: resizeParams.width,
                height: resizeParams.height
              }
            })
            return;
          }
          setResized(true)
          setResizedSize(resizeParams);

          const nodeEle = nodeRef.current;
          const flowNodeEle = nodeEle.parentElement;

          if (flowNodeEle) {
            flowNodeEle.style.height = 'fit-content'
          }
        }}
        color='transparent'
      />
      <>
        {
          form && !(loading || aiResponse) &&
          <div style={{
            margin: '10px',
            paddingBottom: '0px',
            display: 'flex',
            flexDirection: 'column',
          }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', whiteSpace: 'nowrap' }}>
              <div style={{
                marginRight: '6px',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}>
                {form.item.label}
              </div>
              {
                form.item.sub_items &&
                <Selector
                  options={form.item.sub_items.map((item, index) => {
                    return item
                  })}
                  value={form.sub_item.value}
                  onChange={(value) => {
                    setForm({
                      ...form,
                      sub_item: form.item.sub_items.find(subItem => subItem.value === value)
                    })
                  }}
                  inputStyle={{
                    backgroundColor: color_theme.content_bg,
                    borderColor: color_theme.border
                  }}
                />
              }
              {
                form.sub_item?.value === 'draft_more' &&
                <input
                  ref={form_input_ref}
                  value={form.sub_item.label === '...' ? '' : form.sub_item.label || ''}
                  onChange={(event) => {
                    setForm({
                      ...form,
                      sub_item: { ...form.sub_item, label: event.target.value }
                    })
                  }}
                  style={{
                    padding: '2px',
                    marginLeft: '6px',
                    fontSize: '15px',
                    width: '130px',
                    border: `1px solid ${color_theme.border}`,
                    backgroundColor: color_theme.content_bg,
                    borderRadius: '4px',
                    outline: 'none',
                  }}
                  placeholder={intl.formatMessage({ id: 'draft_more_type' })}
                  autoFocus={true}
                />
              }
            </div>

            {
              <>
                {
                  form.ai_generated &&
                  <div style={{
                    fontWeight: 500,
                    fontSize: 16,
                    paddingBottom: 5,
                  }}>
                    {intl.formatMessage({ id: 'more_info_form' })}
                  </div>
                }
                {
                  (!!form.description || !!form.item.desc) &&
                  <div style={{
                    fontSize: 15,
                    color: '#444'
                  }}>
                    {form.description || form.item.desc}
                  </div>
                }
              </>
            }
            {/* {
              showContextNodesSelector && selectable_context_options?.length > 1 &&
              <div style={{
                padding: '10px',
                paddingLeft: 0,
                paddingBottom: '0px',
                columnGap: 6,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                maxWidth: '100%'
              }}>
                <span style={{ whiteSpace: 'nowrap' }}>
                  {intl.formatMessage({ id: 'selectable_context' })}
                </span>
                <Selector
                  options={selectable_context_options.map((option, index) => {
                    return option
                  })}
                  value={selectable_context_option}
                  onChange={(value) => {
                    set_selectable_context_option(value)
                  }}
                  inputStyle={{
                    backgroundColor: color_theme.content_bg,
                    borderColor: color_theme.border
                  }}
                />
              </div>
            } */}
            {
              form.item?.subTasks?.filter(item => !item.to_arg)?.map((subTask) => {
                return <div
                  key={subTask.label}
                  style={{ paddingTop: '8px', display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '6px', marginBottom: '4px' }}>
                  {renderSubTaskButton(subTask)}
                </div>
              })
            }

            {
              form.args.map((arg, index) => {
                if (arg.disabled) {
                  return null
                }

                const subTask = form.item?.subTasks?.find(sub => sub.to_arg === arg.name);

                return <div key={index} style={{ paddingTop: '8px' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', columnGap: '6px', rowGap: '4px', marginBottom: '4px' }}>
                    <div style={{ color: '#222', fontSize: 16 }}>{arg.label}</div>
                    {
                      !arg.readOnly && !form.ai_generated &&
                      <div style={{ color: '#666', fontSize: '14px' }}> {'(' + intl.formatMessage({ id: arg.required ? 'arg_required' : 'arg_optional' }) + ')'} </div>
                    }
                    {
                      !!arg.description?.trim() &&
                      <div className={selected ? 'nodrag' : undefined} style={{ color: '#666', fontSize: '14px', cursor: 'text' }}>
                        {'(' + arg.description + ')'}
                      </div>
                    }
                    {
                      subTask && renderSubTaskButton(subTask)
                    }
                  </div>
                  {
                    arg.readOnly && arg.value &&
                    <div style={{
                      borderRadius: '3px',
                      color: '#333', outline: 'none',
                      backgroundColor: '#f8f8f8',
                      paddingLeft: '8px', paddingRight: '8px', paddingTop: '4px', paddingBottom: '4px',
                      fontSize: 14,
                      maxHeight: '200px',
                      overflowX: 'hidden',
                      overflowY: 'auto'
                    }}>
                      <MarkdownRenderer content={arg.value} />
                    </div>
                  }
                  {
                    ['text', 'textarea'].includes(arg.type) && !arg.readOnly &&
                    <textarea
                      ref={index === 0 ? form_input_ref : null}
                      rows={(arg.name === 'other_reqs' || form.ai_generated) && 3 || 5}
                      className={selected ? 'nodrag nowheel' : undefined}
                      autoComplete='off'
                      placeholder={arg.hint}
                      style={{
                        width: '-webkit-fill-available',
                        border: `1px solid ${color_theme.border}`,
                        backgroundColor: color_theme.content_bg,
                        borderRadius: '3px',
                        color: '#333', outline: 'none',
                        paddingLeft: '4px', paddingRight: '4px',
                        fontSize: 15
                      }}
                      value={arg.value || ''}
                      onChange={(event) => {
                        let args = [...form.args];
                        args[index] = {
                          ...args[index],
                          value: event.target.value
                        }

                        setForm({
                          ...form,
                          args
                        })
                      }}
                    />
                  }
                  {
                    ['textline', 'input'].includes(arg.type) && !arg.readOnly &&
                    <input
                      ref={index === 0 ? form_input_ref : null}
                      className={selected ? 'nodrag' : undefined}
                      autoComplete='off'
                      placeholder={arg.hint}
                      style={{
                        width: '-webkit-fill-available',
                        border: `1px solid ${color_theme.border}`,
                        backgroundColor: color_theme.content_bg,
                        borderRadius: '3px',
                        color: '#333', outline: 'none',
                        paddingLeft: '4px', paddingRight: '4px',
                        fontSize: 15, paddingTop: '4px', paddingBottom: '4px'
                      }}
                      value={arg.value || ''}
                      onChange={(event) => {
                        let args = [...form.args];
                        args[index] = {
                          ...args[index],
                          value: event.target.value
                        }

                        setForm({
                          ...form,
                          args
                        })
                      }}
                    />
                  }
                  {
                    arg.type === 'select' && !arg.readOnly &&
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', whiteSpace: 'nowrap' }}>
                      <Selector
                        options={arg.options.map((option, index) => {
                          return option
                        })}
                        value={arg.value}
                        inputStyle={{
                          minHeight: '32.5px',
                          backgroundColor: color_theme.content_bg,
                          borderColor: color_theme.border
                        }}
                        onChange={(value) => {
                          let args = [...form.args];
                          args[index] = {
                            ...args[index],
                            value
                          }

                          setForm({
                            ...form,
                            args
                          });

                        }}
                      />
                      {
                        (!arg.value || !arg.options.find(option => option.value === arg.value)) &&
                        <input
                          autoComplete='off'
                          placeholder={intl.formatMessage({ id: 'user_input_option' })}
                          style={{
                            width: '-webkit-fill-available',
                            border: `1px solid ${color_theme.border}`,
                            backgroundColor: color_theme.content_bg,
                            borderRadius: '3px',
                            color: '#333', outline: 'none',
                            paddingLeft: '4px', paddingRight: '4px',
                            marginLeft: '8px',
                            fontSize: 15, paddingTop: '4px', paddingBottom: '4px'
                          }}
                          value={arg.value || ''}
                          onChange={(event) => {
                            let args = [...form.args];
                            args[index] = {
                              ...args[index],
                              value: event.target.value
                            }

                            setForm({
                              ...form,
                              args
                            })
                          }}
                        />
                      }
                    </div>
                  }
                </div>
              })
            }

            {
              best_output_format &&
              <div style={{ paddingTop: '8px' }}>
                <div style={{ color: '#222', fontSize: 15, marginBottom: 10 }}>{intl.formatMessage({ id: 'generate_content_format' })}</div>

                <div
                  className='fill-available'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    columnGap: 6
                  }}>
                  <Selector
                    options={Output_Formats}
                    value={best_output_format}
                    inputStyle={{
                      backgroundColor: color_theme.content_bg,
                      borderColor: color_theme.border
                    }}
                    onChange={(value) => {
                      set_best_output_format(value);

                      let action = value === 'text' && 'query' || value === 'mindmap' && 'flow_brainstorming' || value === 'todo_list' && 'flow_task_breakdown';
                      let queryType = value === 'text_generation' && 'query' || value === 'mindmap' && 'brainstorming' || value === 'todo_list' && 'todos';

                      let newForm = {
                        ...form,
                        item: {
                          action
                        }
                      };

                      updateNodeData(nodeId, {
                        ai_action: action,
                        queryType: queryType,
                        form: newForm
                      })

                      form && setForm(newForm)
                    }}
                  />
                </div>
              </div>
            }

            {
              (errMsg || aiResponse?.err) &&
              <div style={{ color: 'red', fontSize: 13 }}>
                {
                  errMsg || aiResponse?.err
                }
              </div>
            }

            <div style={{
              padding: '6px',
              paddingTop: 12,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              width: '-webkit-fill-available'
            }}>
              {
                !loading && form &&
                <div
                  className={'hover_shadow'}
                  style={{
                    paddingTop: '4px',
                    paddingBottom: '4px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    color: 'white', cursor: 'pointer',
                    backgroundColor: subTasking ? '#ddd' : color_theme.border,
                    borderRadius: '14px',
                    whiteSpace: 'nowrap',
                    fontSize: '14px'
                  }}
                  onClick={() => !subTasking && askAI()}
                >
                  {intl.formatMessage({ id: 'submitAI' })}
                </div>
              }
            </div>
          </div>
        }

        {
          ['aigc', 'funblocks_doc'].includes(nodeType) &&
          (!form || loading) &&
          <>
            {
              // !['brainstorming_perspective'].includes(data.ai_action) &&
              <NodeTitle nodeType={nodeType} queryType={data.queryType} title={title} url={data.context?.url} color_theme={color_theme} />
            }
            {
              // !errMsg && !aiResponse?.err &&
              <div
                className={isMobile || !selected ? undefined : 'nodrag'}
                style={{
                  margin: '12px',
                  marginRight: (data.todos || data.items) ? 5 : 12,
                  marginTop: 7,
                  marginBottom: 7,
                  fontSize: 14,
                  overflowY: 'auto',
                  cursor: 'text',
                  // display: 'contents'
                  // minHeight: 40
                }}
                ref={contentContainerRef}
                onMouseUp={(event) => {
                  const text = getSelectionText();

                  if (text) {
                    onContextMenu({ type: 'text_selection', event, selected_text: text, nodeId, setContextMenu })
                  }
                }}
                onMouseDown={(event) => {
                  window.getSelection().removeAllRanges();
                  setContextMenu(null);
                }}
              >
                <div>
                  {
                    content && !['breakdown', 'brainstorming_perspective', 'slideshow'].includes(data.ai_action) && !data.todos?.length &&
                    <MarkdownRenderer content={content} artifacts={artifacts} setArtifacts={setArtifacts} setSelectedArtifact={setSelectedArtifact} setArtifactOffsetTop={setArtifactOffsetTop} />
                  }
                  {
                    content && ['breakdown', 'brainstorming_perspective', 'slideshow'].includes(data.ai_action) &&
                    <div>
                      {intl.formatMessage({ id: 'askAI_doing' })}
                    </div>
                  }

                  {
                    !!data.brainstorming_scenario &&
                    <div style={{
                      fontSize: 13,
                      color: 'GrayText'
                    }}>
                      {
                        data.brainstorming_scenario
                      }
                    </div>
                  }

                  {
                    data.items &&
                    // ['breakdown'].includes(data.ai_action) &&
                    <AIActionList
                      nodeId={nodeId}
                      list={data.items}
                      queryType={'tell_more'}
                      // queryType={data.ai_action == 'breakdown' && 'tell_more' || data.queryType === 'brainstorming_insights' && 'tell_more' || undefined}
                      // action={data.queryType === 'perspective' && 'breakdown' || undefined}
                      aiItemClicked={aiItemClicked}
                      onDeleteItem={(index) => {
                        const items = [...data.items];
                        items.splice(index, 1);
                        updateNodeData(nodeId, { items })

                        setSavingTrigger(Math.random());
                      }}
                      breakdownEnabled={true}
                    />
                  }

                  {
                    data.todos &&
                    <TodoList
                      nodeId={nodeId}
                      list={data.todos}
                      priorities={data.priorities}
                      queryType={'task_breakdown'}
                      aiItemClicked={aiItemClicked}
                    />
                  }

                  {
                    !loading &&
                    !['perspective', 'brainstorming_insights'].includes(data.queryType) &&
                    !['flow_brainstorming', 'mindmap_primary_branch'].includes(data.ai_action) &&
                    !(
                      ['breakdown'].includes(data.ai_action) && data.items ||
                      ['flow_todolist', 'flow_task_breakdown'].includes(data.ai_action) && data.todos ||
                      !['flow_brainstorming', 'flow_todolist', 'flow_task_breakdown', 'breakdown'].includes(data.ai_action) && content
                    )
                    && <div>
                      {intl.formatMessage({ id: 'no_aigc_content' })}
                    </div>
                  }
                </div>
              </div>
            }

            {
              (errMsg || aiResponse?.err) &&
              <div style={{
                margin: '12px',
                marginTop: 10,
                marginBottom: 10,
                fontSize: 14,
                // minHeight: 40
              }}>
                <div style={{ color: 'red', fontSize: 13 }}>
                  {
                    errMsg || aiResponse?.content
                  }
                </div>

                {/* {
                  aiResponse?.err !== 'exceed_msg_limit' && searchResult
                } */}

                {
                  aiResponse?.err == 'exceed_msg_limit' &&
                  <div style={{ display: 'flex', flexDirection: 'row', padding: '10px', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ paddingLeft: '16px', paddingRight: '16px', paddingTop: '4px', paddingBottom: '4px', cursor: 'pointer', color: 'white', fontWeight: 'bold', backgroundColor: 'dodgerblue', borderRadius: '6px' }} onClick={() => gotoMarket()}>{intl.formatMessage({ id: 'upgrade_to_vip' })}</div>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-end'
                    }} >
                      <span style={{ marginLeft: '8px', marginRight: '4px', fontSize: '14px', color: 'gray' }}>{intl.formatMessage({ id: 'or_invite_friend_rewards' })}</span>
                      <div style={{ paddingLeft: '16px', paddingRight: '16px', paddingTop: '4px', paddingBottom: '4px', cursor: 'pointer', color: 'white', fontWeight: 'bold', backgroundColor: 'limegreen', borderRadius: '6px' }} onClick={() => inviteFriends()}>{intl.formatMessage({ id: 'invite_friends' })}</div>
                    </div>
                  </div>
                }
              </div>
            }

            {
              loading && !doing?.backgroundTask &&
              <div style={{ marginRight: '6px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: 60 }}>
                <CircularProgress size={18} />
                <span style={{ marginLeft: '8px', marginRight: '8px', fontSize: '14px', color: 'gray', whiteSpace: 'nowrap' }}>
                  {intl.formatMessage({ id: 'askAI_doing' })}
                </span>
              </div>
            }

            {
              searchResult
            }
          </>
        }

        {
          nodeType == 'prompt' &&
          <>
            <PromptNode
              defaultValue={userInput || ''}
              queryTypeChangable={true}
              onChange={(value) => setUserInput(value)}
              loading={context_loading}
              triggered={triggered}
              hasAIMenu={itemTargeted != -1}
              handleConfirm={(user_behavior, prompt, options) => {
                set_prompt_node_submit({
                  user_behavior,
                  prompt,
                  options,
                  trigger: Math.random()
                })
              }}
              saveUserInput={(userInput) => {
                updateNodeData(nodeId, { userInput })
              }}
              nodeId={nodeId}
              queryType={data.queryType == 'dynamic' && (!groupedAssistantItems?.length ? 'dynamic' : 'dynamic_with_menu') || data.queryType}
              color_theme={color_theme}
              callAI={aiItemClicked}
              selected={selected}
              onKeyDown={onKeyDown}
            />
            <div style={{
              position: 'absolute',
              left: 0,
              top: 'calc(100% + 4px)',
            }}>
              {data?.queryType === 'dynamic' && !triggered && AIMenu}
            </div>
          </>
        }

        {
          nodeType == 'note' &&
          <>
            <NoteEditor
              id={nodeId}
              isMobile={isMobile}
              color_theme={color_theme}
              value={data.content}
              onChange={(value) => {
                updateNodeData(nodeId, { content: value });
                setSavingTrigger(Math.random());
              }}
              hovered={aigc_hovered}
              getSelectionText={getSelectionText}
              onContextMenu={onContextMenu}
              toPrompt={(data) => {
                updateNodeData(nodeId, { ...data, nodeType: 'prompt' })
              }}
            />
          </>
        }

        {
          nodeType == 'image' &&
          <>
            {
              !!data.content?.src &&
              <img
                src={data.content.src}
                ref={imgRef}
                style={{ borderRadius: 4 }}
              />
            }
            {
              !!data.content?.caption?.trim() &&
              <div style={{
                position: 'absolute',
                width: '100%',
                left: '50%',
                top: 'calc(100% + 16px)',
                transform: 'translateX(-50%)',
                color: color_theme.border,
                display: 'flex',
                justifyContent: 'center'
              }}>
                <div>
                  {data.content.caption}
                </div>
              </div>
            }
          </>
        }

        {
          nodeType == 'slides' &&
          <>
            {
              data.hid &&
              <RevealPresentation
                hid={data.hid}
                width={resizedSize?.width || node?.measured?.width || defaultWidth}
                height={resizedSize?.height || node?.measured?.height || defaultWidth * 3 / 4}
              />
            }
          </>
        }

        {
          nodeType === 'group' && <div style={{
            width: node?.style?.width,
            height: node?.style?.height,
            backgroundColor: 'rgba(0,0,0, 0.06)',
            border: `1px solid ${color_theme.border}`,
            position: 'relative'
          }} >
            {
              aigc_hovered &&
              <Tooltip
                title={intl.formatMessage({ id: 'ungroup_nodes' })}
                placement='top'
              >
                <div
                  className='transparent-background'
                  style={{
                    width: 30,
                    height: 30,
                    color: '#777',
                    position: 'absolute',
                    top: 3,
                    right: 3,
                    zIndex: 10
                  }}
                  onClick={() => {
                    ungroup();
                  }}
                >
                  <Close size={21} />
                </div>
              </Tooltip>
            }
          </div>
        }

        {
          !(['flow_brainstorming'].includes(data.ai_action) && triggered) &&
          !['prompt', 'group'].includes(nodeType) &&
          <div style={{
            position: 'absolute',
            top: 2,
            right: 2,
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: (['image', 'note'].includes(nodeType) || form) && color_theme.content_bg || ['slides'].includes(nodeType) && 'transparent' || color_theme.title_bg,
            // paddingLeft: 6,
            borderBottomLeftRadius: 4,
            cursor: 'pointer'
          }}>
            {
              aigc_hovered && <>
                {
                  ['slides'].includes(nodeType) &&
                  <Tooltip title={intl.formatMessage({ id: 'slide_present_tooltip' })} placement="top">
                    <div
                      className='transparent-background'
                      style={{
                        width: 25,
                        height: 25,
                        color: '#777'
                      }}
                      onClick={async () => {
                        let uri = await get_server_host() + 'present.html?hid=' + data.hid;
                        window.open(uri, "_blank")
                        return;
                      }}
                    >
                      <PlayBtn
                        size={16}
                      />
                    </div>
                  </Tooltip>

                }
                {
                  !isReadOnly && !['image', 'note'].includes(nodeType) && !node.parentId && !loading && !form &&
                  <Tooltip
                    title={intl.formatMessage({ id: nodeType == 'slides' ? 'slide_edit_tooltip' : 'edit_title_placeholder' })}
                    placement='top'
                  >
                    <div
                      className='transparent-background'
                      style={{
                        width: 25,
                        height: 25,
                        color: '#777'
                      }}
                      onClick={() => {
                        if (nodeType == 'slides') {
                          window.open(`${window.location.origin}/#/slidesEditor?hid=${data.hid}`, '_blank')
                        } else {
                          dispatch({
                            type: FLOW_INPUT_MODAL,
                            value: {
                              id: 'node_title',
                              value: data.title,
                              nodeId,
                              multiline: true,
                              visible: true
                            }
                          })
                        }

                      }}
                    >
                      <Edit size={17} />
                    </div>
                  </Tooltip>
                }

                {
                  !isReadOnly && !node.parentId && (errMsg || aiResponse?.err) && !loading && data.ai_action && nodeType === 'aigc' && !['brainstorming_insights'].includes(data.queryType) &&
                  <Tooltip
                    title={intl.formatMessage({ id: 'try_again' })}
                    placement='top'
                  >
                    <div
                      className='transparent-background'
                      style={{
                        width: 25,
                        height: 25,
                        color: '#777'
                      }}
                      onClick={() => retry()}
                    >
                      <Refresh size={17} />
                    </div>
                  </Tooltip>
                }

                {
                  (data.queryType === 'ask_question' || data.form?.ai_generated) &&
                  <Tooltip
                    title={intl.formatMessage({ id: 'save_to_prompt' })}
                    placement='top'
                  >
                    <div
                      className='transparent-background'
                      style={{
                        width: 25,
                        height: 25,
                        color: '#777'
                      }}
                      onClick={savePrompt}
                    >
                      <PinAngle size={16} />
                    </div>
                  </Tooltip>
                }

                {
                  !form && nodeType !== 'slides' &&
                  <Tooltip
                    title={intl.formatMessage({ id: data.minimized && 'expand' || 'collapse' })}
                    placement='top'
                  >
                    <div
                      className='transparent-background'
                      style={{
                        width: 25,
                        height: 25,
                        color: '#777'
                      }}
                      onClick={() => {
                        updateNodeData(nodeId, { minimized: !data.minimized })
                      }}
                    >
                      {
                        !data.minimized &&
                        <ArrowMinimize size={16} />
                      }
                      {
                        !!data.minimized &&
                        <ArrowMaximize size={16} />
                      }
                    </div>
                  </Tooltip>
                }
                {
                  !isReadOnly && !node.parentId && !data.minimized &&
                  <Tooltip
                    title={intl.formatMessage({ id: 'remove_node' })}
                    placement='top'
                  >
                    <div
                      className='transparent-background'
                      style={{
                        width: 25,
                        height: 25,
                        color: '#777'
                      }}
                      onClick={() => {
                        setSavingTrigger(Math.random());
                        deleteNode(nodeId);
                      }}
                    >
                      <Close size={16} />
                    </div>
                  </Tooltip>
                }
              </>
            }

            {
              !aigc_hovered && data.minimized && <Tooltip
                title={intl.formatMessage({ id: 'expand' })}
                placement='top'
              >
                <div
                  className='transparent-background'
                  style={{
                    width: 25,
                    height: 25,
                    color: '#777'
                  }}
                  onClick={() => {
                    updateNodeData(nodeId, { minimized: false })
                  }}
                >
                  <ArrowMaximize size={16} />
                </div>
              </Tooltip>
            }
          </div>
        }
        {
          (['link'].includes(data.queryType) || ['funblocks_doc'].includes(nodeType)) && nodeType != 'prompt' &&
          <div
            className='fill-available'
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end'
            }}
          >
            <div
              onClick={async () => {
                let url = data.context?.url;
                if (nodeType === 'funblocks_doc') {
                  url = `${window.location.origin}/#/editor?hid=${data.hid}&space=workspace`
                }
                window.open(url, '_blank');
              }}
              style={{
                color: color_theme.border,
                border: 'none',
                padding: '8px 12px',
                cursor: 'pointer',
                textAlign: 'center'
              }}
            >
              {intl.formatMessage({ id: 'to_original_page' })}
            </div>
          </div>
        }
      </>
      {
        nodeType == 'note' &&
        <div
          className='fill-available'
          style={{
            position: 'absolute',
            bottom: 2,
            right: 2,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end'
          }}
        >
          <PromptTypeDropdownAndActionButton
            color_theme={color_theme}
            nodeType={'note'}
            queryTypeChangable={true}
            triggered={triggered}
            loading={false}
            isDropdown={menuDroppedDown === 'prompt_type'}
            setDropdown={(dropdown) => {
              setMenuDroppedDown(dropdown && 'prompt_type')
            }}

            actionEnabled={false}
            onSelect={(item) => {
              if (item.value != 'note') {
                updateNodeData(nodeId, { queryType: item.value, userInput: marked.parse(data.content), nodeType: 'prompt' })
              }
            }}
          />
        </div>
      }
      <Popover
        open={Boolean(sub_menu_visible)}
        onClose={() => close_sub_menu()}
        onClick={(e) => {
          e.stopPropagation();
        }}
        anchorEl={sub_menu_anchor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: 'transparent'
        }}
        onKeyDown={onKeyDown}
      >
        <div
          style={{ ...styles.container, borderColor: color_theme.border, backgroundColor: color_theme.content_bg, width: 200, margin: 0 }}
        >
          {
            sub_menu_items?.map((item, index) => {
              return <div
                ref={(ref) => {
                  sub_menu_item_refs.current = { ...sub_menu_item_refs.current, [index]: ref };
                }}
                key={index + ''}
              >
                <div
                  key={index + ''}
                  onClick={(e) => { sub_menu_item_clicked(item) }}
                  className='hoverStand'
                  style={{ backgroundColor: index === sub_menu_item_targeted ? color_theme.title_bg : undefined, flexDirection: 'row', justifyContent: 'space-between', padding: '5px', paddingLeft: '12px' }}
                >
                  {item.label}
                  {index === sub_menu_item_targeted && <KeyboardReturn size={20} style={{ cursor: 'pointer', color: color_theme.border }} />}
                </div>
              </div>
            })
          }
        </div>
      </Popover>
    </div >
  )
}

const styles = {
  container: {
    backgroundColor: 'white',
    border: '1px solid gray',
    boxShadow: '0px 0px 8px #bbb',
    margin: '6px',
    marginLeft: 0,
    borderRadius: '4px'
  },
  contentSect: {
    fontSize: 13,
    color: 'gray'
  },
  toolbarIcon: {
    width: 35,
    height: 35,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  }
}

export default AINode;
