import React, { useEffect, useRef, useState } from 'react';
import { Tooltip } from '@mui/material';
import { toPng } from 'html-to-image';
import { useIntl } from 'react-intl';
import { Close, Download } from '@styled-icons/material';
import MermaidRenderer from '../common/MermaidRenderer';

const Artifact = ({ selectedArtifact, aigc_hovered, setSelectedArtifact, offsetTop, color_theme }) => {
    const intl = useIntl();
    const iframeRef = useRef(null);
    const containerRef = useRef(null);
    const [iframeSize, setIframeSize] = useState({ width: 320, height: 240 });
    const [isResizing, setIsResizing] = useState(false);
    const [resized, setResized] = useState(false);
    const [resizeDirection, setResizeDirection] = useState(null);
    const [aspectRatio, setAspectRatio] = useState(null);

    useEffect(() => {
        if (iframeSize.width && iframeSize.height) {
            setAspectRatio(iframeSize.width / iframeSize.height);
        }
    }, [iframeSize.width, iframeSize.height]);

    const handleDownload = () => {
        const element = document.getElementById('artifact-content');
        toPng(element)
            .then((dataUrl) => {
                const link = document.createElement('a');
                link.download = 'artifact.png';
                link.href = dataUrl;
                link.click();
            })
            .catch((error) => {
                console.error('下载图片时出错:', error);
            });
    };

    useEffect(() => {
        const iframe = iframeRef.current;
        if (!iframe || selectedArtifact?.type === 'SVG') return;

        const checkOverflow = () => {
            const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
            const bodyElement = iframeDocument.body;

            const isOverflowingHorizontally = bodyElement.scrollWidth > iframeRef.current?.clientWidth;
            const isOverflowingVertically = bodyElement.scrollHeight > iframeRef.current?.clientHeight;

            if (isOverflowingHorizontally) {
                setIframeSize(prev => ({ ...prev, width: bodyElement.scrollWidth + 24 }));
            }
            if (isOverflowingVertically) {
                setIframeSize(prev => ({ ...prev, height: bodyElement.scrollHeight + 24 * bodyElement.scrollHeight / bodyElement.scrollWidth }));
            }
        };

        const handleIframeLoad = () => {
            checkOverflow();
        };

        iframe.addEventListener('load', handleIframeLoad);

        // Set initial content
        let content = selectedArtifact?.content || '';
        if (!content.includes('<html')) {
            content = `<html><body>${content}</body></html>`;
        }
        iframe.srcdoc = content;

        return () => {
            iframe.removeEventListener('load', handleIframeLoad);
        };
    }, [selectedArtifact]);

    const handleMouseDown = (e, direction) => {
        e.preventDefault();
        setIsResizing(true);
        setResizeDirection(direction);
    };

    const handleMouseMove = (e) => {
        if (!isResizing) return;

        setResized(true);

        const container = containerRef.current;
        const rect = container.getBoundingClientRect();

        if (resizeDirection === 'horizontal') {
            const iframeDocument = iframeRef?.current?.contentDocument || iframeRef?.current?.contentWindow?.document;
            const bodyElement = iframeDocument?.body;

            const newWidth = e.clientX - rect.left;
            if (iframeDocument) {
                setIframeSize({ width: newWidth, height: bodyElement.scrollHeight + 24 * bodyElement.scrollHeight / bodyElement.scrollWidth });
            } else {
                setIframeSize(prevState => ({ ...(prevState || {}), width: newWidth }));
            }
        } else if (resizeDirection === 'vertical') {
            const newHeight = e.clientY - rect.top;
            setIframeSize(prev => ({ ...prev, height: newHeight }));
        } else if (resizeDirection === 'corner') {
            const newWidth = e.clientX - rect.left;
            const newHeight = e.clientY - rect.top;

            if (aspectRatio) {
                if (newWidth / newHeight > aspectRatio) {
                    setIframeSize({ width: newHeight * aspectRatio, height: newHeight });
                } else {
                    setIframeSize({ width: newWidth, height: newWidth / aspectRatio });
                }
            } else {
                setIframeSize({ width: newWidth, height: newHeight });
            }
        }
    };

    const handleMouseUp = () => {
        setIsResizing(false);
        setResizeDirection(null);
    };

    useEffect(() => {
        if (isResizing) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
        }
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isResizing]);

    return (
        <div
            ref={containerRef}
            className='nodrag'
            style={{
                position: 'absolute',
                left: 'calc(100% + 20px)',
                top: offsetTop,
                minWidth: 320,
                width: iframeSize && resized ? `${iframeSize.width}px` : 'fit-content',
                height: iframeSize && resized ? `${iframeSize.height}px` : undefined,
                border: `1px solid #ccc`,
                backgroundColor: color_theme.content_bg,
                boxShadow: '0px 0px 8px #bbb',
                overflow: 'hidden',
            }}
        >
            <div id="artifact-content" style={{ position: 'relative' }}>
                {
                    selectedArtifact?.type === 'SVG' &&
                    <div dangerouslySetInnerHTML={{ __html: selectedArtifact.content }} />
                }
                {
                    selectedArtifact?.type === 'HTML' &&
                    <iframe
                        ref={iframeRef}
                        style={{
                            width: `${iframeSize.width}px`,
                            height: `${iframeSize.height}px`,
                            border: 'none',
                            backgroundColor: 'white',
                        }}
                    />
                }
                {
                    selectedArtifact?.type === 'Mermaid' &&
                    <MermaidRenderer id={'mermaid-chart'} source={selectedArtifact.content} />
                }
            </div>

            {aigc_hovered && (
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                }}>
                    <Tooltip
                        title={intl.formatMessage({ id: 'export_to_image' })}
                        placement='top'
                    >
                        <div
                            className='transparent-background'
                            style={{
                                width: 25,
                                height: 25,
                                color: '#777',
                                cursor: 'pointer',
                            }}
                            onClick={handleDownload}
                        >
                            <Download size={20} />
                        </div>
                    </Tooltip>

                    <Tooltip
                        title={intl.formatMessage({ id: 'close' })}
                        placement='top'
                    >
                        <div
                            className='transparent-background'
                            style={{
                                width: 25,
                                height: 25,
                                color: '#777',
                                cursor: 'pointer',
                            }}
                            onClick={() => setSelectedArtifact(null)}
                        >
                            <Close size={20} />
                        </div>
                    </Tooltip>
                </div>
            )}

            {
                // selectedArtifact?.type === 'HTML' &&
                <>
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            width: 5,
                            height: '100%',
                            cursor: 'ew-resize',
                        }}
                        onMouseDown={(e) => handleMouseDown(e, 'horizontal')}
                    />

                    <div
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            width: '100%',
                            height: 5,
                            cursor: 'ns-resize',
                        }}
                        onMouseDown={(e) => handleMouseDown(e, 'vertical')}
                    />

                    <div
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                            width: 10,
                            height: 10,
                            cursor: 'nwse-resize',
                        }}
                        onMouseDown={(e) => handleMouseDown(e, 'corner')}
                    />
                </>
            }
        </div>
    );
};

export default Artifact;