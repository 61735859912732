import * as React from 'react';
import { useIntl } from 'react-intl';
import useStoreWithUndo from './store';
import { useShallow } from 'zustand/react/shallow';
import { useMediaQuery } from 'react-responsive';
import ContentEditable from 'react-contenteditable';
import TurndownService from 'turndown';
import { ArrowDownS, ArrowRightS } from '@styled-icons/remix-line'
import { selector } from './AINode';
import { Tooltip } from '@mui/material'
import { DropDownMenu } from './DropDownMenu';
import { PromptTypeDropdownAndActionButton, doableAfterTriggeredTypes } from './PromptTypeDropdownAndActionButton';
import ReactGA from "react-ga4";
import { MOBILE_MEDIA_QUERY } from '@/utils/constants';

const urlRegex = require('url-regex');

const Topic_Types = [{
  id: 'thematic_learning'
}, {
  id: 'personal_growth'
}, {
  id: 'startup_idea'
}, {
  id: 'bussiness_analysis'
}, {
  id: 'investment_analysis'
}, {
  id: 'product_innovation'
}, {
  id: 'solutions_design'
}, {
  id: 'project_planning'
}, {
  id: 'marketing_strategies'
}, {
  id: 'branding_building'
}, {
  id: 'advertisiing_compaign_ideas'
}, {
  id: 'root_cause_analysis'
}, {
  id: 'team_management'
}, {
  id: 'operation_optimization'
}, {
  id: 'innovative_ideas'
}, {
  id: 'event_planning'
}, {
  id: 'decision_analysis'
}, {
  id: 'meeting_disucssions'
}, {
  id: 'travel_experiences'
}, {
  id: 'writing_ideas'
}, {
  id: 'research_topics',
}, {
  id: 'curriculum_design',
}, {
  id: 'social_issue_solutions'
}, {
  id: 'other'
}]

const Thinking_Models = [{
  id: 'swot_analysis'
}, {
  id: 'business_model_canvas'
}, {
  id: 'fivew1h_method'
}, {
  id: 'scamper_method'
}, {
  id: 'six_thinking_hats'
}, {
  id: 'pdca'
}, {
  id: 'systems_thinking'
}, {
  id: 'steep_analysis'
}, {
  id: 'five_forces'
}, {
  id: 'four_p'
}, {
  id: 'triz'
}, {
  id: 'rephrazing'
}, {
  id: 'learning_pyramid',
}, {
  id: 'kwl'
}, {
  id: 'changing_perspectives'
}, {
  id: 'reverse_thinking'
}, {
  id: 'role_playing'
}, {
  id: 'mckinsey_7S_framework'
  // }, {
  // id: 'value_proposition_design'
}, {
  id: 'other'
}]

export const PromptNode = ({ nodeId, selected, defaultValue, onKeyDown, onChange, onQueryTypeChange, queryTypeOptions, handleConfirm, saveUserInput, triggered, loading, queryType, color_theme, queryTypeChangable, callAI, hasAIMenu }) => {
  const intl = useIntl();
  const [inputChanged, setInputChanged] = React.useState();
  const [isValid, setIsValid] = React.useState();
  const [menuDroppedDown, setMenuDroppedDown] = React.useState();
  const [showOptions, setShowOptions] = React.useState();
  const [scenario, setScenario] = React.useState();
  const [thinking_model, set_thinking_model] = React.useState();

  const textInputRef = React.useRef();
  const scenarioInputRef = React.useRef();
  const thinkingModelInputRef = React.useRef();
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);
  const [showInputTips, setShowInputTips] = React.useState();
  const [isMultiline, setIsMultiline] = React.useState(false);
  const [initialHeight, setInitialHeight] = React.useState(null);
  const [input, setInput] = React.useState();
  const [isInputZh, setIsInputZh] = React.useState();

  const { updateNodeData } = useStoreWithUndo(
    useShallow(selector)
  );

  const toggleOptionsButton = React.useMemo(() => !triggered && queryType === 'brainstorming', [triggered, queryType]);

  const optionsButton = React.useMemo(() => {
    return toggleOptionsButton && (
      <Tooltip
        title={intl.formatMessage({ id: 'advanced_settings' })}
        placement='bottom'
      >
        <div
          className='transparent-background'
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: 4,
            color: '#777',
            cursor: 'pointer'
          }}
          onClick={() => {
            setShowOptions(prevState => !prevState);

            setTimeout(() => {
              textInputRef.current?.focus();

              const range = document.createRange();
              range.selectNodeContents(textInputRef.current);
              range.collapse(false);
              const selection = window.getSelection();
              selection.removeAllRanges();
              selection.addRange(range);
            }, 100);
          }}
        >
          {showOptions ? <ArrowDownS size={16} /> : <ArrowRightS size={16} />}
        </div>
      </Tooltip>
    )
  }, [toggleOptionsButton, showOptions])

  React.useEffect(() => {
    setTimeout(() => {
      const editableDiv = textInputRef.current;
      if (editableDiv) {
        editableDiv.focus();
      }
    }, 100);

  }, [textInputRef?.current]);

  React.useEffect(() => {
    if (defaultValue && !input) {
      setInput(defaultValue.replaceAll('\n', '<br>'))
    }
  }, [defaultValue, input])

  React.useEffect(() => {
    const turndownService = new TurndownService();
    const mrkd = turndownService.turndown(input || '')?.trim();

    let isValid = !!mrkd?.trim();

    const isValidUrl = urlRegex({ exact: true }).test(mrkd?.trim());
    if (queryType == 'link') {
      isValid = isValidUrl;
    } else if (queryType != 'note' && isValidUrl) {
      handleQueryTypeChange('link');
    }

    setIsValid(isValid);
  }, [input, queryType]);

  React.useEffect(() => {
    const turndownService = new TurndownService();
    const mrkd = turndownService.turndown(input || '')?.trim();

    setShowInputTips(selected && queryType != 'link' && (queryType != 'dynamic' && mrkd?.length > 3 || queryType == 'dynamic' && !hasAIMenu));
  }, [input, selected, queryType, hasAIMenu]);

  const handlePaste = React.useCallback((e) => {
    e.preventDefault();

    const text = e.clipboardData.getData('text/plain');
    document.execCommand('insertText', false, text);
  }, []);

  const Selectable_TopicTypes = React.useMemo(() => Topic_Types.map(item => {
    return {
      value: item.id,
      label: intl.formatMessage({ id: item.id })
    }
  }), [intl])

  const Selectable_Thinking_Models = React.useMemo(() => Thinking_Models.map(item => {
    return {
      value: item.id,
      label: intl.formatMessage({ id: item.id })
    }
  }), [intl])

  React.useEffect(() => {
    if (textInputRef.current && initialHeight === null) {
      setInitialHeight(textInputRef.current.clientHeight);
    }
  }, []);

  React.useEffect(() => {
    if (!input || queryType == 'link') {
      setIsMultiline(false);
      return;
    }

    if (textInputRef.current && initialHeight !== null && !isInputZh) {
      const currentHeight = textInputRef.current.clientHeight;
      setIsMultiline(prevState => !!prevState || currentHeight > initialHeight);
    }
  }, [input, initialHeight, queryType, isInputZh]);

  const handleInputChange = React.useCallback((event) => {
    setInputChanged(true);
    !!onChange && onChange(event.currentTarget.innerText || event.currentTarget.textContent);
    setInput(['new_task', 'new_sub_topic', 'add_improve_plan', 'link'].includes(queryType) ? (event.currentTarget.textContent || event.currentTarget.innerText) : event.target.value);
  }, [queryType]);

  const handleInputConfirm = React.useCallback((eventType) => {
    const content = textInputRef.current.innerText || textInputRef.current.textContent;
    handleConfirm(eventType, content, showOptions && {
      scenario,
      thinking_model
    });

    ReactGA.event({ category: 'promptNode', action: 'trigger_ai_enter', label: queryType });

    setInput('');
  }, [input, showOptions, queryType, scenario, thinking_model, textInputRef])

  const handleQueryTypeChange = React.useCallback((queryType, value) => {
    if(onQueryTypeChange) {
      return onQueryTypeChange(queryType);
    }

    if (queryType === 'note') {
      const turndownService = new TurndownService();
      const mrkd = turndownService.turndown(value)?.trim();
      updateNodeData(nodeId, { nodeType: queryType, content: mrkd });
    } else {
      updateNodeData(nodeId, { queryType: queryType });
      textInputRef.current?.focus();
      const range = document.createRange();
      range.selectNodeContents(textInputRef.current);
      range.collapse(false);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }, [])

  return (
    <div
      style={{
        position: 'relative',
        width: '-webkit-fill-available',
        backgroundColor: queryType == 'dynamic' && triggered ? '#eee' : color_theme.content_bg,
        borderRadius: 4,
        padding: 6,
        paddingRight: queryTypeChangable && (!triggered || doableAfterTriggeredTypes.includes(queryType)) ? 0 : 6,
        paddingLeft: toggleOptionsButton && !isMultiline ? 1 : 8
      }}
      onKeyPress={(event) => {
        if (event.key == 'Enter' && (!event.shiftKey || queryType === 'link')) {
          event.preventDefault();
          event.stopPropagation();

          if (!loading) {
            handleInputConfirm('key_enter');
          }
        }
      }}

      onKeyDown={onKeyDown}

      onClick={() => {
        setMenuDroppedDown(null);
      }}
    >
      <div style={{
        position: 'relative',
        display: 'flex',
        flexDirection: isMultiline ? 'column' : 'row',
        alignItems: isMultiline ? 'flex-start' : 'center',
        justifyContent: 'space-between',
        width: '-webkit-fill-available',
        columnGap: 2
      }}>
        {!isMultiline && optionsButton}
        {!loading && (!triggered || ['ask', 'ask_question', 'search', 'perspective', 'mindmap_primary_branch', 'idea', 'new_sub_topic', 'new_task', 'new_insight'].includes(queryType)) && (
          <>
            <ContentEditable
              id={'promptInput_' + nodeId}
              innerRef={textInputRef}
              className={isMobile ? 'fill-available' : `fill-available nodrag ${isMultiline ? 'nowheel' : ''}`}
              style={{
                // width: `calc(100% - ${isMultiline ? 6 : 35}px)`,
                border: '0px',
                outline: 'none',
                fontSize: 15,
                alignContent: 'flex-end',
                backgroundColor: color_theme.content_bg,
                cursor: 'text',
                maxHeight: 400,
                overflowY: 'auto',
                marginLeft: isMultiline ? 2 : 0,
                paddingRight: isMultiline ? 5 : 0,
                // borderBottom: showOptions ? `1px solid ${color_theme.title_bg}` : undefined
              }}
              html={input || ''}
              onChange={(e) => {
                handleInputChange(e);
              }}
              onBlur={() => {
                inputChanged && saveUserInput && saveUserInput(input);
                setInputChanged(false);
              }}
              onCompositionStart={() => setIsInputZh(true)}
              onCompositionEnd={() => setIsInputZh(false)}
              onPaste={handlePaste}
            />
            {!input && (
              <div
                style={{
                  position: 'absolute',
                  left: toggleOptionsButton ? 26 : 1,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  pointerEvents: 'none',
                  fontSize: 13,
                  fontWeight: 380,
                  color: '#a3a3a3',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'block',
                }}
              >
                {intl.formatMessage({ id: 'flow_' + queryType + '_placeholder' })}
              </div>
            )}
          </>
        )}
        {(loading || (!doableAfterTriggeredTypes.includes(queryType) && triggered)) && (
          <div style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
            dangerouslySetInnerHTML={{ __html: input }} />
        )}
        <div
          className={isMultiline ? 'fill-available' : undefined}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: isMultiline && !!optionsButton ? 'space-between' : 'flex-end',
            marginTop: isMultiline ? 6 : 0,
          }}>
          {
            isMultiline && optionsButton
          }
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              columnGap: 6
            }}
          >
            {
              isMultiline &&
              <div style={{
                color: 'gray',
                fontSize: 14,
              }}>
                {intl.formatMessage({ id: 'softbreak_tips' })}
              </div>
            }
            <PromptTypeDropdownAndActionButton
              nodeId={nodeId}
              color_theme={color_theme}
              queryType={queryType}
              queryTypeOptions={queryTypeOptions}
              queryTypeChangable={queryTypeChangable}
              triggered={triggered}
              loading={loading}
              isDropdown={menuDroppedDown === 'prompt_type'}
              setDropdown={(dropdown) => {
                setMenuDroppedDown(dropdown && 'prompt_type')
              }}
              actionEnabled={isValid}
              onActionButtonClicked={() => {
                isValid && handleInputConfirm('click_submit')
              }}
              onSelect={(item) => handleQueryTypeChange(item.value, input)}
            />
          </div>

        </div>
      </div>
      {
        showOptions && toggleOptionsButton && <div
          className='fill-available'
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginLeft: 4,
            marginRight: 0,
            marginTop: 6,
          }}>
          <span style={{
            color: '#777',
            fontSize: 12,
            whiteSpace: 'nowrap'
          }}>
            {intl.formatMessage({ id: 'topic_type' })}
          </span>
          <input
            ref={scenarioInputRef}
            className='nodrag fill-available'
            placeholder={intl.formatMessage({ id: 'type_or_select_placeholder' })}
            style={{
              fontSize: 13,
              border: '0px',
              outline: 'none',
              backgroundColor: 'transparent',
              minWidth: 100,
              marginLeft: 6,
              borderBottom: showOptions ? `1px solid ${color_theme.title_bg}` : undefined,
            }}

            value={scenario || ''}
            onChange={(event) => {
              setScenario(event.target.value?.trim());
            }}
          />
          <DropDownMenu
            items={Selectable_TopicTypes}
            isDropdown={menuDroppedDown === 'selectable_topics'}
            setDropdown={(dropdown) => {
              setMenuDroppedDown(dropdown && 'selectable_topics')
            }}

            tooltip={intl.formatMessage({ id: 'toggle_options' })}
            color_theme={color_theme}
            onSelect={(item) => {
              item.value != 'other' && setScenario(item.label);
              scenarioInputRef.current?.focus();
            }}
          />
        </div>
      }

      {
        showOptions && toggleOptionsButton && <div
          className='fill-available'
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginLeft: 4,
            marginRight: 0,
            marginTop: 7,
            fontSize: 14,
          }}>
          <span style={{
            color: '#777',
            fontSize: 12,
            whiteSpace: 'nowrap'
          }}>
            {intl.formatMessage({ id: 'thinking_model' })}
          </span>
          <input
            ref={thinkingModelInputRef}
            className='nodrag fill-available'
            placeholder={intl.formatMessage({ id: 'type_or_select_placeholder' })}
            style={{
              fontSize: 13,
              border: '0px',
              outline: 'none',
              backgroundColor: 'transparent',
              minWidth: 100,
              marginLeft: 6,
              borderBottom: showOptions ? `1px solid ${color_theme.title_bg}` : undefined
            }}
            value={thinking_model || ''}
            onChange={(event) => {
              set_thinking_model(event.target.value?.trim())
            }}
          />
          {
            !!Selectable_Thinking_Models?.length &&
            <DropDownMenu
              items={Selectable_Thinking_Models}
              isDropdown={menuDroppedDown === 'thinking_models'}
              setDropdown={(dropdown) => {
                setMenuDroppedDown(dropdown && 'thinking_models')
              }}

              tooltip={intl.formatMessage({ id: 'toggle_options' })}
              color_theme={color_theme}
              onSelect={(item) => {
                item.value != 'other' && set_thinking_model(item.label);
                thinkingModelInputRef.current?.focus();
              }}

              explainIt={(model) => { callAI({ item: { action: 'query' }, userInput: `[Thinking model]: ${model}`, queryType: 'tell_more', standAlone: true }) }}
            />
          }
        </div>
      }
      {
        !isMultiline && showInputTips && !triggered && <div style={{
          position: 'absolute',
          top: '100%',
          right: 0,
          color: 'gray',
          fontSize: 14,
          paddingTop: 6
        }}>
          {intl.formatMessage({ id: 'softbreak_tips' })}
        </div>
      }
    </div>)
};
