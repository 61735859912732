import React, { useState } from 'react';
import { Panel, useReactFlow, getNodesBounds, getViewportForBounds, useViewport } from '@xyflow/react';
import { toPng } from 'html-to-image';
import { Download } from '@styled-icons/bootstrap/Download';
import { useIntl } from 'react-intl';
import { Tooltip, CircularProgress } from '@mui/material';
import QRCode from 'qrcode';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from "react-ga4";
import { EXPORT_DOC_DIALOG } from '@/constants/actionTypes';

function downloadImage(dataUrl, title) {
    const a = document.createElement('a');
    a.setAttribute('download', title + '.png');
    a.setAttribute('href', dataUrl);
    a.click();
}

function getImageInfo(node, nodes, isGroup = false) {
    if (node && !isGroup) {
        return {
            imageWidth: (node.measured?.width || node.computed?.width),
            imageHeight: (node.measured?.height || node.computed?.height),
            target: document.querySelector(`.react-flow__node[data-id="${node.id}"]`),
            transform: [0, 0, 1]
        };
    } else {
        const nodesBounds = isGroup ?
            {
                ...node.position,
                ...(node.measured || node.computed)
            }
            : getNodesBounds(nodes, { nodeOrigin: [0, 0] });
        const { x, y, zoom } = getViewportForBounds(nodesBounds, nodesBounds.width, nodesBounds.height, 0.2, 2, 0);
        const transform = [x, y, zoom];
        return {
            imageWidth: nodesBounds.width,
            imageHeight: nodesBounds.height,
            target: document.querySelector('.react-flow__viewport'),
            transform,
        };
    }
}

async function toPngAndDownload(target, options, imageType, withoutQrcode) {
    const dataUrl = await toPng(target, options);

    // 获取图像的宽度和高度
    const image = new Image();
    image.src = dataUrl;
    await new Promise((resolve) => {
        image.onload = resolve;
    });
    const imageWidth = image.width;
    const imageHeight = image.height;

    const infoRectHeight = withoutQrcode && 52 || (imageType === 'board' ? 220 : 150);

    // 计算画布宽度和高度，确保能容纳整个图像和文本
    const canvasWidth = Math.max(imageWidth, options.width);
    const canvasHeight = Math.max(imageHeight, options.height) + infoRectHeight;

    const canvas = document.createElement('canvas');
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;
    const ctx = canvas.getContext('2d');

    // 设置背景色
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // 在canvas上绘制图像
    ctx.drawImage(image, 0, 0);
    ctx.fillStyle = 'black';

    if (withoutQrcode) {
        ctx.font = `22px Arial`;
        ctx.fillStyle = 'gray';
        ctx.fillText(options.info.prodcut, canvas.width - ctx.measureText(options.info.prodcut).width - 12, canvas.height - 20);
    } else {
        // 生成QR码
        const qrCodeUrl = options.info.shareUrl; // 当前页面的URL
        const qrCode = await QRCode.toDataURL(qrCodeUrl);

        const qrCodeSize = infoRectHeight * 0.9;
        const padding = (infoRectHeight - qrCodeSize) / 2;

        const qrCodeX = canvas.width - qrCodeSize - padding;
        const qrCodeY = canvas.height - qrCodeSize - padding;
        const qrCodeImage = new Image();
        qrCodeImage.src = qrCode;
        await new Promise((resolve) => {
            qrCodeImage.onload = resolve;
        });
        ctx.drawImage(qrCodeImage, qrCodeX, qrCodeY, qrCodeSize, qrCodeSize);

        ctx.font = `${imageType === 'board' ? 30 : 26}px Arial`;
        ctx.fillStyle = '#444';
        ctx.fillText(options.info.qrCodeTips, qrCodeX - ctx.measureText(options.info.qrCodeTips).width - padding, qrCodeY + qrCodeSize - (imageType === 'board' ? 85 : 60));

        ctx.font = `22px Arial`;
        ctx.fillStyle = 'gray';
        ctx.fillText(options.info.prodcut, qrCodeX - ctx.measureText(options.info.prodcut).width - padding, qrCodeY + qrCodeSize - 20);
    }

    const newDataUrl = canvas.toDataURL('image/png');
    downloadImage(newDataUrl, options.title);
}

function DownloadButton({ title, node, onClick, shareUrl, imageType = 'board', isGroup = false, iconStyle }) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { getNodes } = useReactFlow();
    const [isDownloading, setIsDownloading] = useState(false);
    const flow_export_image_without_qrcode = useSelector(state => state.uiState.flow_export_image_without_qrcode);

    const onBtnClick = async () => {
        !!onClick && onClick();

        if (node?.data?.nodeType === 'slides') {
            dispatch({ type: EXPORT_DOC_DIALOG, value: { visible: true, doc: { title: node.data.title, type: node.data.nodeType, hid: node.data.hid } } });
            return;
        }

        setIsDownloading(true);

        const { imageWidth, imageHeight, target, transform } = getImageInfo(node, getNodes(), isGroup);
        const options = {
            title,
            backgroundColor: 'white',
            width: imageWidth + 20,
            height: imageHeight + 20,
            info: {
                prodcut: intl.formatMessage({ id: 'generated_by_flow' }),
                qrCodeTips: intl.formatMessage({ id: 'qr_code_tips' }),
                shareUrl
            },
            quality: 1,
            style: {
                width: imageWidth,
                height: imageHeight,
                margin: '10px',
                transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
            },
            filter: (node) => {
                const exclusionClasses = ['circle-button'];
                return !exclusionClasses.some((classname) => node.classList?.contains(classname));
            },
        };
        await toPngAndDownload(target, options, imageType, flow_export_image_without_qrcode);
        setIsDownloading(false);

        ReactGA.event({ category: 'BoardButtons', action: 'download', label: isGroup ? 'group' : (!!node ? 'node' : 'board') });
    };

    return (
        <Tooltip title={intl.formatMessage({ id: node?.data?.nodeType === 'slides' ? 'export_to_pdf' : 'export_to_image' })} placement="top">
            <div className="hoverStand" style={{ padding: 6, ...iconStyle }} onClick={isDownloading ? undefined : onBtnClick}>
                {isDownloading ? <CircularProgress size={18} /> : <Download color='#555' size={18} />}
            </div>
        </Tooltip>
    );
}

export default DownloadButton;